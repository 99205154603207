import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import DownloadFleetCards from "./Components/DownloadFleetCards";
import InvokeInfonetSyncTasks from "./Components/invokeInfonetSyncTasks";
import InvokePetrovendSyncTasks from "./Components/invokePetrovendSyncTasks";
import MigrateMemo from "./Components/MigrateMemo";
import InvokeUpdateCachedStatistics from "./Components/invokeUpdateCachedStatistics";
import { baseUrl } from "../../api/axios";

import {
  Layout,
  Form,
  Button,
  Typography,
  notification,
  Breadcrumb,
  Radio,
  Space,
  Upload,
  Image,
  message,
  Divider,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import breakpoint from "../../Components/breakpoint";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Title } = Typography;

const ApplicationSettings = () => {
  const [frmAppSettings] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadFleetCardWindowOpen, setIsDownloadFleetCardWindowOpen] =
    useState(false);
  const [
    isInvokePetrovendSyncTaskWindowOpen,
    setIsInvokePetrovendSyncTaskWindowOpen,
  ] = useState(false);
  const [
    isInvokeInfonetSyncTaskWindowOpen,
    setIsInvokeInfonetSyncTaskWindowOpen,
  ] = useState(false);
  const [logoPath, setLogoPath] = useState(null);
  const [uploading, setUploading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const initCheck = useRef(true);

  useEffect(() => {
    const loadSettings = async () => {
      setIsLoading(true);
      try {
        const res_settings = await axiosPrivate.get(`/admin/settings`);
        const {
          site_closed,
          site_closed_note,
          full_path,
          api_key,
          api_enabled,
        } = res_settings.data;
        frmAppSettings.setFieldsValue({
          site_closed: site_closed ? 1 : 0,
          site_closed_note,
          api_enabled: api_enabled ? 1 : 0,
          api_key,
        });
        setLogoPath(full_path);
      } catch (err) {
        notification.error({
          message: err.message,
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        await loadSettings();
      })();
    }
  }, []);

  const appSettingSubmitCallback = async (data) => {
    try {
      setSubmitting(true);
      const res_save_settings = await axiosPrivate.patch(
        `/admin/settings`,
        data
      );
      notification.success({
        message: "Successfully saved application settings.",
        placement: "bottom",
        duration: 4,
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const invokePetrovendTasks = async () => {
    try {
      const { data } = await axiosPrivate.post(
        `/admin/fleetcard-toolbox/invoke-petrovend-tasks`,
        {}
      );

      const { success, message } = data;
      if (!success) {
        notification.warning({
          message,
          placement: "bottom",
          duration: 4,
        });
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    }
  };
  return (
    <Content
      className="admin-generic-content"
      style={{
        minWidth: 1,
        maxWidth: breakpoint.lg,
        width: breakpoint.lg,
        justifySelf: "start",
        alignSelf: "flex-start",
      }}
    >
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Application Settings
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Admin Control Panel",
            },

            {
              title: "Application Settings",
            },
          ]}
        ></Breadcrumb>
      </div>
      <DownloadFleetCards
        open={isDownloadFleetCardWindowOpen}
        onClose={() => {
          setIsDownloadFleetCardWindowOpen(false);
        }}
      />
      <InvokePetrovendSyncTasks
        open={isInvokePetrovendSyncTaskWindowOpen}
        onClose={() => {
          setIsInvokePetrovendSyncTaskWindowOpen(false);
        }}
      />
      <InvokeInfonetSyncTasks
        open={isInvokeInfonetSyncTaskWindowOpen}
        onClose={() => {
          setIsInvokeInfonetSyncTaskWindowOpen(false);
        }}
      />
      <div className="admin-generic-card">
        <div className="admin-generic-card-body">
          <Form
            size="middle"
            form={frmAppSettings}
            style={{ margin: "20px 20px 20px 0" }}
            labelCol={{ span: 8, offset: 0 }}
            wrapperCol={{ span: 10, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            layout="vertical"
            onFinish={appSettingSubmitCallback}
            disabled={submitting || isLoading}
          >
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="left"
            >
              Site Basics
            </Divider>
            <Form.Item
              label="Site Temporarily Closed"
              style={{ marginBottom: 10 }}
              name="site_closed"
              initialValue={0}
            >
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={0} defaultChecked>
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="App Logo"
              valuePropName="file"
              tooltip="Only .png or .jpeg supported, size limit: 2MB. This will take up to an hour to show changes in the site logo."
            >
              <Upload
                name="logo"
                listType="picture-card"
                showUploadList={false}
                maxCount={1}
                accept="image/png, image/jpeg"
                withCredentials={true}
                beforeUpload={(file) => {
                  const isJpgOrPng =
                    file.type === "image/jpeg" || file.type === "image/png";
                  if (!isJpgOrPng) {
                    message.error("You can only upload JPG/PNG file!");
                  }
                  const isLt2M = file.size / 1024 / 1024 < 2;
                  if (!isLt2M) {
                    message.error("Image must smaller than 2MB!");
                  }
                  return isJpgOrPng && isLt2M;
                }}
                customRequest={async ({
                  file,
                  filename,
                  data,
                  onProgress,
                  onError,
                  onSuccess,
                }) => {
                  const formData = new FormData();
                  formData.append(filename, file);
                  try {
                    const { db_full_path } = (
                      await axiosPrivate.post(
                        "/admin/company-profile-logo",
                        formData,
                        {
                          headers: { "Content-Type": "multipart/form-data" },
                          onUploadProgress: ({ total, loaded }) => {
                            onProgress(
                              {
                                percent: Math.round(
                                  (loaded / total) * 100
                                ).toFixed(2),
                              },
                              file
                            );
                          },
                        }
                      )
                    ).data;
                    onSuccess({ full_path: db_full_path });
                  } catch (err) {
                    onError(err);
                  }
                  return {
                    abort() {
                      console.log("upload progress is aborted.");
                    },
                  };
                }}
                onChange={(fileInfo) => {
                  if (fileInfo.file.status === "uploading") {
                    setUploading(true);
                  } else if (fileInfo.file.status === "done") {
                    setUploading(false);
                    setLogoPath(fileInfo.file.response?.full_path);
                  }
                }}
              >
                <div>
                  {logoPath ? (
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        borderRadius: 10,
                      }}
                      preview={false}
                      src={`${baseUrl}${logoPath}`}
                    />
                  ) : (
                    <>
                      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div>Upload</div>
                    </>
                  )}
                </div>
              </Upload>
            </Form.Item>
            <Form.Item
              name="site_closed_note"
              label="Site Closed Note"
              style={{ marginBottom: 10, minWidth: 0 }}
              rules={[{ type: "string", max: 200 }]}
              initialValue={""}
            >
              <TextArea
                maxLength={200}
                style={{ height: 80, minWidth: 0 }}
              ></TextArea>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="left"
            >
              Rest API
            </Divider>
            <Form.Item
              label="External API Enabled"
              style={{ marginBottom: 10 }}
              name="api_enabled"
              initialValue={0}
            >
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="api_key"
              label="API Key"
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <TextArea maxLength={200} style={{ height: 100 }}></TextArea>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="left"
            >
              Toolkits
            </Divider>
            <Form.Item
              label="Fleet Card Toolbox"
              style={{
                marginBottom: 10,
                minWidth: 0,
                minHeight: 100,
              }}
              layout="vertical"
            >
              <Space direction="vertical">
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => {
                    setIsDownloadFleetCardWindowOpen(true);
                  }}
                >
                  Download North60 Fleet Cards
                </Button>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => {
                    setIsInvokePetrovendSyncTaskWindowOpen(true);
                  }}
                >
                  Invoke Petrovend Sync Tasks
                </Button>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={async () => {
                    setIsInvokeInfonetSyncTaskWindowOpen(true);
                  }}
                >
                  Invoke InfoNet Sync Tasks
                </Button>
                <MigrateMemo />
              </Space>
            </Form.Item>

            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="left"
            >
              Misc Functions
            </Divider>
            <Form.Item
              style={{
                marginBottom: 10,
                minWidth: 0,
                minHeight: 100,
              }}
              layout="vertical"
            >
              <Space direction="vertical">
                <InvokeUpdateCachedStatistics />
              </Space>
            </Form.Item>
            <Form.Item style={{ marginTop: 40 }}>
              <Button htmlType="submit" type="primary" loading={submitting}>
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Content>
  );
};

export default ApplicationSettings;
