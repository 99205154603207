import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Line } from "@ant-design/charts";
import {
  Layout,
  Typography,
  Statistic,
  Flex,
  Card,
  Table,
  Row,
  Col,
  Grid,
} from "antd";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import moment from "moment/moment";
import { useInterval } from "react-interval-hook";
import numberFormatter from "number-formatter";

const { Content } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Dashboard = () => {
  const screens = useBreakpoint();
  const axiosPrivate = useAxiosPrivate();
  const [isloading, setIsLoading] = useState(false);
  const [activeCardPerSite, setActiveCardPerSite] = useState([]);
  const [latestTransactions, setLatestTransactions] = useState([]);
  const [cardsOperated, setCardsOperated] = useState([]);
  const [statistictisUpdatedTimestamp, setStatistictisUpdatedTimestamp] =
    useState([]);
  const { start, stop, isActive } = useInterval(
    async () => {
      await loadData();
    },
    1000 * 60,
    { autoStart: false, immediate: false }
  );

  useEffect(() => {
    if (!isActive()) {
      start();
    }
  }, []);

  useEffect(() => {
    if (isActive()) {
      return () => {
        stop();
      };
    }
  }, []);

  const hasInit = useRef(false);
  useEffect(() => {
    if (!hasInit.current) {
      (async () => {
        await loadData();
        hasInit.current = true;
      })();
    }
  }, []);

  const shouldHideProduct = () => {
    console.log(screens.sm, screens.md, screens.lg, screens.xl, screens.xxl);
    if (screens.xs) {
      return true;
    } else if (
      screens.sm &&
      !screens.md &&
      !screens.lg &&
      !screens.xl &&
      !screens.xxl
    ) {
      return true;
    } else if (
      screens.sm &&
      screens.md &&
      screens.lg &&
      !screens.xl &&
      !screens.xxl
    ) {
      return true;
    }
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/regular/dashboard`, {
        timeout: 1000 * 30,
      });

      const {
        success,
        active_cards_summary,
        latest_transactions,
        cards_operated,
        datetime_updated,
      } = data;
      if (success) {
        setActiveCardPerSite(active_cards_summary);
        setLatestTransactions(latest_transactions);
        setCardsOperated(
          cards_operated?.map((row) => ({
            date: moment(row?.timestamp).format("MM-DD"),
            value: row?.total,
          }))
        );
        setStatistictisUpdatedTimestamp(
          moment(datetime_updated).format("HH:mm")
        );
      }
    } catch (err) {
      setActiveCardPerSite([]);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Content
      className="admin-generic-content"
      // style={{ minWidth: 1, maxWidth: breakpoint.xxl, width: breakpoint.xxl }}
    >
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Dashboard
        </Title>
        {/* <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
          ]}
        ></Breadcrumb> */}
      </div>
      <div className="admin-generic-card">
        <div style={{ height: 10 }}></div>
        <div className="admin-generic-card-body">
          <Row justify={"space-between"}>
            <Col
              sm={24}
              xs={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                marginBottom: 20,
                paddingRight: screens.xxl || screens.xl || screens.lg ? 10 : 0,
              }}
            >
              <Card
                style={{ height: 350, boxShadow: "1px 1px 5px #efefef" }}
                title={"Latest Transactions of Today"}
                extra={
                  <Text style={{ fontSize: 12, color: "grey" }}>
                    Updated: {statistictisUpdatedTimestamp}
                  </Text>
                }
              >
                <Table
                  pagination={{
                    position: ["none", "none"],
                    defaultPageSize: 200,
                  }}
                  size="small"
                  scroll={{ y: 210 }}
                  style={{ height: 210 }}
                  dataSource={latestTransactions}
                  rowKey={(row) => row?.quantity}
                  loading={isloading}
                  columns={[
                    {
                      title: "Time",
                      dataIndex: "timestamp",
                      align: "center",
                      width: 120,
                      render: (value, record, index) => {
                        return moment(value).format("HH:mm");
                      },
                      sortOrder: "descend",
                      sorter: (a, b) => {
                        return moment(
                          a.timestamp,
                          "YYYY-MM-DD HH:mm:ss"
                        ).isAfter(moment(b.timestamp, "YYYY-MM-DD HH:mm:ss"));
                      },
                    },
                    {
                      title: "Site",
                      dataIndex: "sitename",
                      align: "left",
                      hidden:
                        screens.xxl ||
                        screens.xl ||
                        screens.lg ||
                        screens.md ||
                        screens.sm
                          ? false
                          : true,
                    },
                    // {
                    //   title: "Category",
                    //   align: "center",
                    //   responsive: ["sm"],
                    //   width: 120,
                    // },
                    // {
                    //   title: "Pump",
                    //   align: "center",
                    //   responsive: ["lg"],
                    //   width: 80,
                    // },
                    {
                      title: "Product",
                      dataIndex: "product",
                      align: "center",
                      width: 100,
                      hidden: shouldHideProduct(),
                    },
                    {
                      title: "Quantity",
                      dataIndex: "quantity",
                      align: "right",
                      responsive: ["xs", "sm"],
                      width: 120,
                      render: (value, record, index) => {
                        return numberFormatter("###,###,##0.0 L", value);
                      },
                    },
                  ]}
                />
              </Card>
            </Col>
            <Col
              sm={24}
              xs={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                marginBottom: 20,
                paddingLeft: screens.xxl || screens.xl || screens.lg ? 10 : 0,
              }}
            >
              <Card
                style={{ height: 350, boxShadow: "1px 1px 5px #efefef" }}
                title={"Number of Cards Operated"}
              >
                <Line
                  height={260}
                  loading={isloading}
                  point={{ shapeField: "square", sizeField: 2 }}
                  style={{ lineWidth: 2 }}
                  data={cardsOperated}
                  xField={"date"}
                  yField={"value"}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Flex gap={20} wrap={true}>
                {activeCardPerSite?.map((site) => (
                  <Card
                    loading={isloading}
                    key={site?.sitename}
                    title={site?.sitename}
                    style={{
                      flex: 1,
                      width: "100%",
                      minWidth: 180,
                      boxShadow: "1px 1px 5px #efefef",
                    }}
                    styles={{
                      body: { padding: 10 },
                      header: { paddingLeft: 10 },
                    }}
                  >
                    <Statistic title={"Active Cards"} value={site?.total} />
                  </Card>
                ))}
              </Flex>
            </Col>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default Dashboard;
