import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../api/axios";
import { baseUrl } from "../api/axios";
import PageSiteClosed from "../Pages/SiteClosed";

const SiteStatus = () => {
  const [isSiteClosed, setIsSiteClosed] = useState(false);
  const [siteClosedNote, setSiteClosedNote] = useState(null);

  useEffect(() => {
    const getSiteStatus = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}/app/site-status`, {
          withCredentials: true,
        });
        // console.log(data);
        const { site_closed, site_closed_note } = data;
        setIsSiteClosed(site_closed);
        setSiteClosedNote(site_closed_note);
      } catch (err) {
        console.error(err);
        setIsSiteClosed(true);
      }
    };

    getSiteStatus();
    return () => {};
  }, []);

  // useEffect(() => {
  // console.log(`isLoading: ${isLoading}`);
  // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  // }, [isLoading]);

  return !isSiteClosed ? (
    <Outlet />
  ) : (
    <PageSiteClosed site_closed_note={siteClosedNote} />
  );
};

export default SiteStatus;
