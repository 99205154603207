import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import scrollIntoView from "scroll-into-view";
import qs from "qs";
import useAuth from "../../context/hooks/useAuth";
import { IconCirclePlus, IconReload, IconCopy } from "@tabler/icons-react";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import {
  Layout,
  Form,
  Table,
  Button,
  Input,
  Checkbox,
  Modal,
  Space,
  Select,
  notification,
  Typography,
  Breadcrumb,
  Radio,
  Tooltip,
  Divider,
  Pagination,
} from "antd";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-json";
import ModuleAccess from "../../Components/ModuleAccess";
import breakpoint from "../../Components/breakpoint";

const { Content } = Layout;
const { Search } = Input;
const { Title } = Typography;

const AdminUsers = () => {
  const { auth } = useAuth();
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [hasInitialised, setHasInitialised] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [jsonErrors, setJsonErrors] = useState([]);
  const [sites, setSites] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [seachUsersStatus, setSearchUsersStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [frmUser] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();
  const [modal, contextHolderConfirm] = Modal.useModal();
  const defaultPageSize = 25;
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["none", "none"],
      current: 1,
      pageSize: defaultPageSize,
    },
  });

  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      defaultSortOrder: "ascend",
      minWidth: 150,
      sorter: (a, b) => {
        return a.username > b.username;
      },
    },
    // {
    //   title: "Display Name",
    //   dataIndex: "display_name",
    //   align: "left",
    //   defaultSortOrder: "ascend",
    //   sorter: (a, b) => {
    //     return a.display_name > b.display_name;
    //   },
    // },
    {
      title: "First Name",
      dataIndex: "firstname",
      align: "left",
      minWidth: 120,
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      align: "left",
      minWidth: 120,
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      minWidth: 200,
      responsive: ["xl"],
    },
    // {
    //   title: "Last Login",
    //   dataIndex: "last_login",
    //   align: "center",
    //   width: 130,
    //   responsive: ["xl"],
    //   render: (text, record, index) => {
    //     return new Date(text).toString();
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 120,
    },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link
              onClick={() => {
                handlerShowDetail(record.id);
              }}
            >
              <EditFilled style={{ fontSize: 15, color: "#4096ff" }} />
            </Link>
            <Link
              onClick={() => {
                handlerDelete(record);
              }}
            >
              <DeleteFilled style={{ fontSize: 15, color: "#cf1322" }} />
            </Link>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        setSearchKey(searchTerm);
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const hasInit = useRef(false);

  useEffect(() => {
    if (!hasInit.current || searchKey !== null || hasInitialised) {
      (async () => {
        await loadUsers();
        hasInit.current = true;
      })();
    }
  }, [seachUsersStatus, searchKey, tableParams.pagination.current]);

  const handlerSearchUserDisabledChanged = async (status) => {
    setSearchUsersStatus(status);
    hasInit.current = false;
  };

  const loadUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/admin/users?q=${encodeURIComponent(
          searchKey ?? ""
        )}&d=${encodeURIComponent(seachUsersStatus)}&${qs.stringify(
          tableParams
        )}`
      );

      const { users, total_count } = data;
      setUsers(users);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total_count,
        },
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
      setHasInitialised(true);
      scrollToTopOfTable();
    }
  };

  const handlerSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlerShowForm = () => {
    setFormAction("add");
    setIsModalOpen(true);
  };

  const handlerCloneUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/admin/user/${selectedRow?.id}`);
      const {
        account_type,
        company_name,
        company_address,
        accessmodule,
        accesstsmusite,
        access_setting,
        app_access_setting,
        webapp_access_setting,
        cms_access_setting,
        loginscope,
        pushnotification,
        receive_notification_siteoffline,
        receive_notification_tank,
        receive_notification_wbp,
        receive_notification_smartrouter,
        receive_notification_ups,
        receive_inventory_extra_low,
        status,
      } = data;

      frmUser?.setFieldsValue({
        account_type,
        company_name,
        company_address,
        accessmodule,
        accesstsmusite: accesstsmusite?.split(","),
        access_setting,
        app_access_setting,
        webapp_access_setting,
        cms_access_setting,
        loginscope,
        pushnotification,
        receive_notification_siteoffline,
        receive_notification_tank,
        receive_notification_wbp,
        receive_notification_smartrouter,
        receive_notification_ups,
        receive_inventory_extra_low,
        status,
      });

      setFormAction("add");
      setCurrentUserId(selectedRow?.id);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    frmUser?.resetFields();
  };

  const handlerShowDetail = async (user_id) => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/admin/user/${user_id}`);
      const {
        account_type,
        company_name,
        company_address,
        username,
        password,
        email,
        mobile,
        firstname,
        lastname,
        department,
        isadmin,
        roles,
        accessmodule,
        accesstsmusite,
        access_setting,
        app_access_setting,
        webapp_access_setting,
        cms_access_setting,
        loginscope,
        pushnotification,
        receive_notification_siteoffline,
        receive_notification_tank,
        receive_notification_wbp,
        receive_notification_smartrouter,
        receive_notification_ups,
        receive_inventory_extra_low,
        status,
      } = data;
      frmUser?.setFieldsValue({
        account_type,
        company_name,
        company_address,
        username,
        password,
        email,
        mobile,
        firstname,
        lastname,
        department,
        isadmin,
        roles,
        accessmodule: accessmodule?.split(","),
        accesstsmusite: accesstsmusite?.split(","),
        access_setting,
        app_access_setting,
        webapp_access_setting,
        cms_access_setting,
        loginscope,
        pushnotification,
        receive_notification_siteoffline,
        receive_notification_tank,
        receive_notification_wbp,
        receive_notification_smartrouter,
        receive_notification_ups,
        receive_inventory_extra_low,
        status,
      });

      setFormAction("edit");
      setCurrentUserId(user_id);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerDelete = async (user) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete this user "${user?.username}"?`,
      onOk: async () => {
        try {
          setSubmitting(true);
          await axiosPrivate.delete(`/admin/user/${user?.id}`);
          notification.success({
            message: `Successfully deleted the user "${user?.username}".`,
            placement: "bottom",
            duration: 4,
          });
          setUsers((state) => {
            const new_state = state.filter((m) => m.id !== user?.id);
            return [...new_state];
          });
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleFormSubmit = async (data) => {
    try {
      setSubmitting(true);
      if (formAction === "add") {
        const res_add = await axiosPrivate.post(`/admin/users/`, {
          ...data,
          accesstsmusite: data?.accesstsmusite?.join(","),
          accessmodule: data?.accessmodule?.join(","),
        });
        notification.success({
          message: `Successfully created new user "${data?.username}".`,
          placement: "bottom",
          duration: 4,
        });
        setUsers((state) => {
          return [...state, { ...res_add.data }];
        });
      } else if (formAction === "edit") {
        const res_update_user = await axiosPrivate.patch(
          `/admin/user/${currentUserId}`,
          {
            ...data,
            accesstsmusite: data?.accesstsmusite?.join(","),
            accessmodule: data?.accessmodule?.join(","),
          }
        );
        notification.success({
          message: `Successfully updated user ${data?.username}.`,
          placement: "bottom",
          duration: 4,
        });
        setUsers((state) => {
          let found_user_index = state.findIndex(
            (user) => user.id === currentUserId
          );
          state[found_user_index] = {
            id: currentUserId,
            account_type: data?.account_type,
            username: data?.account_type,
            firstname: data?.account_type,
            lastname: data?.lastname,
            isadmin: data?.isadmin,
            email: data?.email,
            mobile: data?.mobile,
            pushnotification: data?.pushnotification,
            roles: data?.roles,
            status: data?.status,
          };
          return [...state];
        });
      }
      frmUser?.resetFields();
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: page,
        },
      };
    });
    if (pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ ...tableParams, sorter, ...filters });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
    // refTable.current.scrollTo({ top: 1 });
  };

  const scrollToTopOfTable = () => {
    scrollIntoView(document.querySelector(".scroll-first-row"), {
      align: {
        // top: 0,
        topOffset: 0,
      },
    });
  };

  return (
    <Content
      className="admin-generic-content"
      // style={{ minWidth: 1, maxWidth: breakpoint.xxl, width: breakpoint.xxl }}
    >
      {contextHolderConfirm}
      <Modal
        title={formAction === "add" ? "Add New User" : "Edit User"}
        styles={{
          body: {
            maxHeight: 550,
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        width={650}
        open={isModalOpen}
        onOk={() => {
          frmUser.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          htmlType: "submit",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: submitting }}
        destroyOnClose
        afterOpenChange={async (open) => {
          if (open) {
            try {
              const { data } = await axiosPrivate.get(`/admin/read-site-list`);
              const { sites } = data ?? [];
              setSites(sites);
            } catch (err) {
            } finally {
            }
          }
        }}
      >
        <Content>
          <Form
            size="middle"
            form={frmUser}
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            layout="horizontal"
            onFinish={handleFormSubmit}
            disabled={submitting}
            scrollToFirstError
          >
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Basic Information
            </Divider>
            <Form.Item
              name="status"
              label="Status"
              style={{ marginBottom: 10 }}
              initialValue={"Active"}
            >
              <Radio.Group>
                <Radio value="Active">Active</Radio>
                <Radio value="Inactive">Inactive</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="account_type"
              style={{ marginBottom: 10 }}
              label="Account Type"
              required
              initialValue={"Individual"}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 20,
                },
              ]}
            >
              <Select
                options={[
                  { label: "Individual", value: "individual" },
                  {
                    label: "Business",
                    value: "business",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="username"
              style={{ marginBottom: 10 }}
              label="Username"
              initialValue={""}
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 255,
                  min: 4,
                  whitespace: false,
                },
              ]}
            >
              <Input
                disabled={formAction === "edit" ? true : false}
                minLength={4}
                maxLength={255}
                showCount
              />
            </Form.Item>
            <Form.Item
              name="password"
              style={{ marginBottom: 10 }}
              label="Password"
              initialValue={""}
              required={true}
              rules={[
                {
                  required: true,
                  message: "",
                  type: "string",
                  min: 4,
                  max: 255,
                },
              ]}
            >
              <Input.Password minLength={4} maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="email"
              style={{ marginBottom: 10 }}
              label="Email"
              required
              initialValue={""}
              rules={[{ required: true, type: "email", max: 255 }]}
            >
              <Input maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="mobile"
              style={{ marginBottom: 10 }}
              label="Phone"
              initialValue={""}
              rules={[{ type: "string", max: 45 }]}
            >
              <Input maxLength={45} showCount />
            </Form.Item>
            <Form.Item
              name="firstname"
              style={{ marginBottom: 10 }}
              label="First Name"
              initialValue={""}
              required
              rules={[{ required: true, type: "string", max: 25 }]}
            >
              <Input maxLength={25} showCount />
            </Form.Item>
            <Form.Item
              name="lastname"
              style={{ marginBottom: 10 }}
              label="Last Name"
              initialValue={""}
              rules={[{ type: "string", max: 45 }]}
            >
              <Input maxLength={45} showCount />
            </Form.Item>
            <Form.Item
              name="company_name"
              style={{ marginBottom: 10 }}
              label="Company"
              initialValue={""}
              rules={[{ type: "string", max: 255 }]}
            >
              <Input maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="company_address"
              style={{ marginBottom: 10 }}
              label="Company Address"
              initialValue={""}
              rules={[{ type: "string", max: 255 }]}
            >
              <Input maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="department"
              style={{ marginBottom: 10 }}
              label="Department"
              initialValue={""}
              rules={[{ type: "string", max: 100 }]}
            >
              <Select
                options={[
                  {
                    value: "",
                    label: "Not Set",
                  },
                  {
                    value: "General Management",
                    label: "General Management",
                  },
                  {
                    value: "Marketing",
                    label: "Marketing",
                  },
                  { value: "Operations", label: "Operations" },
                  { value: "Finance", label: "Finance" },
                  { value: "Sales", label: "Sales" },
                  { value: "Human Resource", label: "Human Resource" },
                  { value: "Purchase", label: "Purchase" },
                  { value: "Business", label: "Business" },
                  { value: "Engineering", label: "Engineering" },
                  {
                    value: "Information Technology",
                    label: "Information Technology",
                  },
                  { value: "Legal", label: "Legal" },
                  { value: "Production", label: "Production" },
                  { value: "Risk Management", label: "Risk Management" },
                  { value: "Maintenance", label: "Maintenance" },
                  {
                    value: "Customer Service",
                    label: "Customer Service",
                  },
                  {
                    value: "Transportation",
                    label: "Transportation",
                  },
                  {
                    value: "Other",
                    label: "Other",
                  },
                ]}
              />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Access & Permission
            </Divider>
            <Form.Item
              name="isadmin"
              label="Is Admin"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="roles"
              style={{ marginBottom: 10 }}
              label="Roles"
              required
              initialValue={"1000"}
              rules={[{ required: true, type: "string", max: 100 }]}
            >
              <Select
                options={[
                  { value: "1000", label: "User" },
                  { value: "2000", label: "Operator" },
                  { value: "8000", label: "Admin" },
                  { value: "9000", label: "SysAdmin" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="accessmodule"
              style={{ marginBottom: 10 }}
              label="Modules"
              initialValue={"Cards"}
              rules={[{ type: "array" }]}
              tooltip="Legacy Support"
            >
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                }}
                options={[
                  { label: "Cards", value: "CARD" },
                  { label: "Downloads", value: "TRANSACTION" },
                  { label: "Backups", value: "BACKUP" },
                  { label: "Task Backlog", value: "TASKBACKLOG" },
                  { label: "Users", value: "USER" },
                  { label: "Stations", value: "STATION" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="loginscope"
              style={{ marginBottom: 10 }}
              label="Login Scope"
              initialValue={"WebApp Only"}
              required
              rules={[{ required: true, type: "string", max: 50 }]}
            >
              <Select
                options={[
                  { label: "Mobile App", value: "App Only" },
                  { label: "Web App", value: "WebApp Only" },
                  { label: "Desktop APP", value: "Utility Only" },
                  { label: "Web + Mobile App", value: "WebApp+App" },
                  { label: "Desktop + Mobile APP", value: "Utility+App" },
                  { label: "Desktop + Web App", value: "Utility+WebApp" },
                  {
                    label: "Desktop + Web + Mobile App",
                    value: "Utility+WebApp+App",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="accesstsmusite"
              style={{ marginBottom: 10 }}
              label="Site Access"
              rules={[{ type: "array" }]}
              initialValue={[]}
            >
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                }}
                options={sites}
              />
            </Form.Item>
            <Form.Item
              name="access_setting"
              label="Web APP Access"
              style={{ marginBottom: 10 }}
              initialValue={"{}"}
              rules={[
                {
                  validator: (_, value) => {
                    if (jsonErrors.length) {
                      return Promise.reject("Json syntax error.");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <AceEditor
                mode="json"
                theme="textmate"
                name="blah2"
                fontSize={14}
                lineHeight={19}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                enableBasicAutocompletion={false}
                enableLiveAutocompletion={false}
                enableSnippets={false}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: true }}
                onValidate={(annotations) => {
                  setJsonErrors(annotations);
                }}
                width="99%"
                style={{
                  height: "30em",
                  border: "1px solid rgb(216,216,216)",
                  borderRadius: 5,
                }}
              />
            </Form.Item>
            <Form.Item
              name="app_access_setting"
              label="Mobile APP Access"
              style={{ marginBottom: 10 }}
              initialValue={"{}"}
              rules={[
                {
                  validator: (_, value) => {
                    if (jsonErrors.length) {
                      return Promise.reject("Json syntax error.");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <AceEditor
                mode="json"
                theme="textmate"
                name="blah2"
                fontSize={14}
                lineHeight={19}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                enableBasicAutocompletion={false}
                enableLiveAutocompletion={false}
                enableSnippets={false}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: true }}
                onValidate={(annotations) => {
                  setJsonErrors(annotations);
                }}
                width="99%"
                style={{
                  height: "20em",
                  border: "1px solid rgb(216,216,216)",
                  borderRadius: 5,
                }}
              />
            </Form.Item>
            <Form.Item
              name="cms_access_setting"
              label="ERP/CMS Access"
              style={{ marginBottom: 10 }}
              initialValue={"{}"}
              rules={[
                {
                  validator: (_, value) => {
                    if (jsonErrors.length) {
                      return Promise.reject("Json syntax error.");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <AceEditor
                mode="json"
                theme="textmate"
                name="blah2"
                fontSize={14}
                lineHeight={19}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                enableBasicAutocompletion={false}
                enableLiveAutocompletion={false}
                enableSnippets={false}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: true }}
                onValidate={(annotations) => {
                  setJsonErrors(annotations);
                }}
                width="99%"
                style={{
                  height: "30em",
                  border: "1px solid rgb(216,216,216)",
                  borderRadius: 5,
                }}
              />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Miscellaneous
            </Divider>
            <Form.Item
              name="pushnotification"
              label="IOS APP APN"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="receive_notification_smartrouter"
              label="Smart Router APN"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="receive_notification_ups"
              label="UPS Monitor APN"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="receive_notification_siteoffline"
              label="BO Monitor APN"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="receive_notification_tank"
              label="Inventory Monitor APN"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="receive_notification_wbp"
              label="WH1 Bulkplant APN"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          User List
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Admin Control Panel",
            },

            {
              title: "User List",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-header">
          <div className="admin-generic-toolbar">
            <Space size="small" wrap>
              <Search
                allowClear
                disabled={isLoading}
                variant="filled"
                size="middle"
                placeholder="Key in to search"
                onChange={handlerSearchChange}
              />
            </Space>
            <Space size="small" wrap>
              <Tooltip title="Reload">
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  size="middle"
                  icon={<IconReload />}
                  onClick={async () => {
                    await loadUsers();
                  }}
                />
              </Tooltip>
              <Select
                disabled={isLoading}
                size="middle"
                style={{ minWidth: 160 }}
                onChange={handlerSearchUserDisabledChanged}
                defaultValue=""
                options={[
                  { value: "", label: "All Users" },
                  { value: "Active", label: "Active Users" },
                  { value: "Inactive", label: "Disabled Users" },
                ]}
              />
              <Tooltip title="Add User">
                <Button
                  icon={<IconCirclePlus />}
                  size="middle"
                  type="primary"
                  onClick={() => {
                    handlerShowForm();
                  }}
                  disabled={isLoading}
                ></Button>
              </Tooltip>
              <Tooltip title="Clone User">
                <Button
                  icon={<IconCopy />}
                  size="middle"
                  type="default"
                  onClick={() => {
                    handlerCloneUser();
                  }}
                  disabled={isLoading || selectedRow === null}
                ></Button>
              </Tooltip>
            </Space>
          </div>
        </div>
        <div className="admin-generic-card-body">
          <div className="admin-generic-table-wrap">
            <Table
              bordered
              style={{ minWidth: 800 }}
              sticky
              pagination={{
                position: ["none", "none"],
                defaultPageSize: defaultPageSize,
              }}
              columns={columns}
              rowKey={(user) => user.id}
              dataSource={users}
              loading={isLoading}
              showSorterTooltip={false}
              rowClassName={(record, index) => {
                const classes = [];
                if (record.status === "Inactive") {
                  classes.push("disabled-record");
                }
                if (index <= 1) {
                  classes.push("scroll-first-row");
                }
                return classes.join(" ");
              }}
              scroll={{ scrollToFirstRowOnChange: true }}
              rowSelection={{
                hideSelectAll: true,
                onChange: (selectedKeys, selectedRows) => {
                  setSelectedRow(selectedRows?.length ? selectedRows[0] : null);
                },
                type: "radio",
              }}
              onChange={handleTableChange}
            />
          </div>
          <Content className="pagination">
            <Button
              size="middle"
              disabled={isLoading}
              loading={isLoading}
              icon={<IconReload />}
              onClick={async () => {
                await loadUsers();
              }}
            />
            <Pagination
              size="middle"
              defaultCurrent={1}
              disabled={isLoading}
              showSizeChanger={false}
              current={tableParams.pagination.current}
              pageSize={defaultPageSize}
              defaultPageSize={tableParams.pagination.pageSize}
              total={tableParams.pagination?.total}
              onChange={handlePageChange}
              showQuickJumper
            />
          </Content>
        </div>
      </div>
    </Content>
  );
};

export default AdminUsers;
