import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import { Buffer } from "buffer";
import { Parser } from "@json2csv/plainjs";
import isNumber from "isnumber";
import numberFormatter from "number-formatter";
import { IconFileTypeCsv, IconCheck } from "@tabler/icons-react";
import { useWindowWidth } from "@react-hook/window-size";
import dayjs from "dayjs";

import {
  Layout,
  Form,
  Button,
  Dropdown,
  Typography,
  notification,
  Breadcrumb,
  Table,
  DatePicker,
  Space,
  message,
  Tabs,
} from "antd";

import breakpoint from "../Components/breakpoint";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const ChilkootSalesReport = () => {
  const [frmTransactions] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [segregated, setSegregated] = useState(false);
  const [report, setReport] = useState([]);
  const [detail, setDetail] = useState([]);
  const [reportDate, setReportDate] = useState({});
  const [csvObject, setCSVObject] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const onlyWidth = useWindowWidth();

  const initCheck = useRef(true);

  useEffect(() => {
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {})();
    }
  }, []);

  const callbackSearchDeposit = async (value) => {
    try {
      setSubmitting(true);

      const { date_range } = value;
      const [date1, date2] = date_range;
      const { data } = await axiosPrivate.post(
        "/regular/downloads/chilkoot-sales-report",
        {
          datebegin: dayjs(date1).format("YYYY-MM-DD"),
          dateend: dayjs(date2).format("YYYY-MM-DD"),
          segregated: !segregated,
        }
      );

      const { success, results, resultsDetails } = data;
      if (success) {
        const report =
          results?.find((report) => report?.sitename === "Chilkoot") ?? [];
        const details =
          resultsDetails?.find((report) => report?.sitename === "Chilkoot") ??
          [];
        setReportDate({
          datebegin: dayjs(date1).format("YYYY-MM-DD"),
          dateend: dayjs(date2).format("YYYY-MM-DD"),
          segregated: !segregated,
        });

        setReport(report?.data);
        setDetail(details?.data);
        setCSVObject(
          getDownloadObject(
            report?.data,
            dayjs(date1).format("YYYY-MM-DD"),
            dayjs(date2).format("YYYY-MM-DD")
          )
        );
      } else {
        throw "Failed to search Chilkoot Sales Report";
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
      setReport([]);
      setDetail([]);
      setReportDate({});
      setCSVObject({});
    } finally {
      setSubmitting(false);
    }
  };

  const getDownloadObject = (report, date1, date2) => {
    try {
      const parser = new Parser({
        delimiter: ",",
        includeEmptyRows: false,
        header: false,
      });
      const csvContent = parser.parse(report);
      let bufferObj = Buffer.from(csvContent, "utf8");
      let base64String = `data:text/csv;base64,${bufferObj.toString("base64")}`;
      let filename = `salesreport-chilkoot-${date1}-${date2}.csv`;

      return { success: true, message: "", base64String, filename };
    } catch (err) {
      return { success: false, message: err?.toString() };
    }
  };

  return (
    <Content
      className="admin-generic-content"
      style={{
        maxWidth: breakpoint.lg,
        width: breakpoint.lg,
        justifySelf: "start",
        alignSelf: "flex-start",
      }}
    >
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Chilkoot Sales Report
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Downloads",
            },

            {
              title: "Chilkoot Sales Report",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-body">
          <Form
            form={frmTransactions}
            style={{ margin: "20px 20px 20px 0" }}
            // labelCol={{ span: 7, offset: 0 }}
            wrapperCol={{ offset: 0 }}
            // labelAlign="left"
            layout={onlyWidth >= breakpoint.md ? "inline" : "vertical"}
            onFinish={callbackSearchDeposit}
            disabled={submitting || isLoading}
            size="middle"
          >
            <Form.Item
              label={"Date Range"}
              name={"date_range"}
              required
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (
                      !Array.isArray(value) ||
                      (Array.isArray(value) && value.length !== 2)
                    ) {
                      return Promise.reject("Please select 2 dates.");
                    }
                    const date1 = value[0];
                    const date2 = value[1];
                    const diff = dayjs(date2).diff(dayjs(date1), "months");
                    if (diff > 3) {
                      return Promise.reject("Must be less than 3 month.");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              tooltip={"Detail report only generated for 3 days or less."}
            >
              <RangePicker inputReadOnly allowEmpty />
            </Form.Item>
            <Form.Item>
              <Space size={"small"} wrap={true}>
                <Dropdown.Button
                  disabled={submitting || isLoading}
                  htmlType="submit"
                  type="default"
                  trigger={"click"}
                  // loading={submitting}
                  menu={{
                    items: [
                      {
                        key: "segregated",
                        label: "Segregated",
                        icon: segregated ? (
                          <IconCheck stroke={1.2} size={18} />
                        ) : null,
                        onClick: () => {
                          setSegregated(!segregated);
                          setReport([]);
                          setDetail([]);
                          setReportDate({});
                          setCSVObject({});
                        },
                      },
                      {
                        key: "reset",
                        label: "Reset",
                      },
                    ],
                    onClick: (info) => {
                      if (info?.key === "reset") {
                        frmTransactions.resetFields();
                        setReport([]);
                        setDetail([]);
                        setReportDate({});
                        setCSVObject({});
                      }
                    },
                  }}
                >
                  Search
                </Dropdown.Button>
                <Button
                  disabled={!csvObject?.base64String || submitting}
                  href={csvObject?.base64String}
                  download={csvObject?.filename}
                  type="default"
                  icon={
                    <IconFileTypeCsv
                      stroke={1.2}
                      size={20}
                      style={{ verticalAlign: "middle", marginBottom: 3 }}
                    />
                  }
                >
                  Export
                </Button>
              </Space>
            </Form.Item>
          </Form>
          <Tabs
            items={[
              {
                key: "1",
                label: "Department Report",
                children: (
                  <Table
                    size="middle"
                    bordered
                    loading={isLoading || submitting}
                    columns={[
                      {
                        hidden: segregated,
                        title: "Date",
                        dataIndex: "SALE_DATE",
                        align: "center",
                        defaultSortOrder: "ascend",
                        width: 120,
                        sorter: (a, b) => {
                          return a.SALE_DATE > b.SALE_DATE;
                        },
                        render: (value, record, index) => {
                          return dayjs(value, "YYYY-MM-DD").isValid()
                            ? dayjs(value).format("MM-DD-YYYY")
                            : "";
                        },
                      },
                      {
                        title: "Department",
                        dataIndex: "DEPT_NAME",
                        align: "left",
                        minWidth: 150,
                        responsive: ["sm"],
                      },
                      {
                        title: "Cost",
                        dataIndex: "COST",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("$###,###,###,##0.00", value)
                            : "-";
                        },
                        responsive: ["lg"],
                      },
                      {
                        title: "Quantity",
                        dataIndex: "QUANTITY",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("###,###,###,##0.0", value)
                            : "-";
                        },
                        responsive: ["md"],
                      },
                      {
                        title: "Amount",
                        dataIndex: "AMOUNT",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("$###,###,###,##0.00", value)
                            : "-";
                        },
                      },
                      {
                        title: "Margin",
                        dataIndex: "MARGIN",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("$###,###,###,##0.00", value)
                            : "-";
                        },
                      },
                    ]}
                    dataSource={report}
                    rowKey={(row) => `${row?.SALE_DATE}_${row?.DEPT}`}
                    pagination={{
                      showSizeChanger: false,
                      showQuickJumper: true,
                      position: ["none", "bottomRight"],
                      defaultPageSize: 25,
                    }}
                  />
                ),
              },
              {
                disabled: !detail?.length,
                key: "2",
                label: "Detail Report",
                children: (
                  <Table
                    size="middle"
                    bordered
                    loading={isLoading || submitting}
                    columns={[
                      {
                        title: "Date",
                        dataIndex: "SALE_DATE",
                        align: "center",
                        defaultSortOrder: "ascend",
                        width: 120,
                        sorter: (a, b) => {
                          return a.SALE_DATE > b.SALE_DATE;
                        },
                        render: (value, record, index) => {
                          return dayjs(value, "YYYY-MM-DD").isValid()
                            ? dayjs(value).format("MM-DD-YYYY")
                            : "";
                        },
                        responsive: ["sm"],
                      },
                      {
                        title: "Description",
                        dataIndex: "DESCRIPT",
                        align: "left",
                        minWidth: 150,
                      },
                      {
                        title: "Quantity",
                        dataIndex: "QUANTITY",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("###,###,###,##0.0", value)
                            : "-";
                        },
                        responsive: ["sm"],
                      },
                      {
                        title: "Cost",
                        dataIndex: "COST",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("$###,###,###,##0.00", value)
                            : "-";
                        },
                        responsive: ["md"],
                      },
                      {
                        title: "Price",
                        dataIndex: "PRICE",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("$###,###,###,##0.00", value)
                            : "-";
                        },
                        responsive: ["xl"],
                      },
                      {
                        title: "Margin",
                        dataIndex: "MARGIN",
                        align: "right",
                        width: 120,
                        render: (value, record, index) => {
                          return isNumber(value)
                            ? numberFormatter("$###,###,###,##0.00", value)
                            : "-";
                        },
                      },
                    ]}
                    dataSource={detail}
                    rowKey={(row, index) => index}
                    pagination={{
                      showSizeChanger: false,
                      showQuickJumper: true,
                      position: ["none", "bottomRight"],
                      defaultPageSize: 25,
                    }}
                  />
                ),
              },
            ]}
          ></Tabs>
        </div>
      </div>
    </Content>
  );
};

export default ChilkootSalesReport;
