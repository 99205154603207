import React from "react";
import { Flex, Layout, Typography } from "antd";

const { Footer } = Layout;
const { Text } = Typography;

const SiteFooter = () => {
  return (
    <Footer style={{ maxHeight: 70, padding: 10 }}>
      <Flex justify="center" align="center" wrap="nowrap" vertical>
        <Text>FLEETCARD MANAGER - Crafted by Clark 2024</Text>
      </Flex>
    </Footer>
  );
};

export default SiteFooter;
