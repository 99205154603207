import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import {
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
  MenuOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  IconCashBanknote,
  IconTransactionDollar,
  IconReportAnalytics,
  IconReportMoney,
  IconSettings,
  IconLocation,
  IconUser,
  IconGasStation,
  IconBuildingFactory,
} from "@tabler/icons-react";
import { baseUrl } from "../api/axios.js";
import {
  Layout,
  Flex,
  Avatar,
  Dropdown,
  Button,
  Menu,
  Typography,
  Image,
  Space,
  Modal,
  notification,
  Form,
  Select,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";
import useLogout from "../context/hooks/useLogout";
import { useWindowSize } from "@uidotdev/usehooks";
import BreakPoint from "./breakpoint.js";
import ROLES from "./Role.js";
import useAuth from "../context/hooks/useAuth.js";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate.js";

import { AxiosError } from "axios";

const { Header, Content } = Layout;
const { Text } = Typography;

const SiteHeader = ({
  onMobileMenuClick,
  mobileMenuItems,
  selectedMenuKey,
  webAppAccess,
  userRole,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const decoded = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined;
  const roles = decoded?.UserInfo?.roles || "";
  const logout = useLogout();
  const navigate = useNavigate();
  const [modal, contextHolderConfirm] = Modal.useModal();
  const { width: screenWidth } = useWindowSize();
  const [frmSecurity] = Form.useForm();
  const [frmProfile] = Form.useForm();
  const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isSubmittingData, setIsSubmittingDate] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const items = [
    {
      label: (
        <Text style={{ fontSize: "1.1em", fontWeight: "bold" }}>DASHBOARD</Text>
      ),
      key: "/",
    },
    [ROLES.User, ROLES.Operator, ROLES.Admin, ROLES.SysAdmin].includes(
      userRole
    ) &&
      webAppAccess?.cards?.access &&
      (webAppAccess?.cards?.retail_fleet_card ||
        webAppAccess?.cards?.bulkplant_fleet_card) && {
        label: (
          <Text style={{ fontSize: "1.1em", fontWeight: "bold" }}>
            FLEETCARDS
          </Text>
        ),
        key: "nav-2",
        children: [
          webAppAccess?.cards?.retail_fleet_card && {
            label: "Retail Cards",
            key: "/fleet-cards/retail",
            icon: (
              <IconGasStation size={18} style={{ verticalAlign: "text-top" }} />
            ),
          },
          webAppAccess?.cards?.bulkplant_fleet_card && {
            label: "Bulk Plant Cards",
            key: "/fleet-cards/bulkplant",
            icon: (
              <IconBuildingFactory
                size={18}
                style={{ verticalAlign: "text-top" }}
              />
            ),
          },
        ],
      },
    [ROLES.User, ROLES.Operator, ROLES.Admin, ROLES.SysAdmin].includes(
      userRole
    ) &&
      (webAppAccess?.downloads?.deposits ||
        webAppAccess?.downloads?.transactions ||
        webAppAccess?.downloads?.chilkoot_sales_report ||
        webAppAccess?.downloads?.chilkoot_retail_inventory) &&
      webAppAccess?.downloads?.access && {
        label: (
          <Text style={{ fontSize: "1.1em", fontWeight: "bold" }}>
            DOWNLOADS
          </Text>
        ),
        key: "nav-3",
        children: [
          webAppAccess?.downloads?.deposits && {
            label: "Deposits",
            key: "/downloads/deposits",
            icon: (
              <IconCashBanknote
                size={18}
                style={{ verticalAlign: "text-top" }}
              />
            ),
          },
          webAppAccess?.downloads?.transactions && {
            label: "Transactions",
            key: "/downloads/transactions",
            icon: (
              <IconTransactionDollar
                size={18}
                style={{ verticalAlign: "text-top" }}
              />
            ),
          },
          (webAppAccess?.downloads?.transactions ||
            webAppAccess?.downloads?.deposits) &&
            (webAppAccess?.downloads?.chilkoot_sales_report ||
              webAppAccess?.downloads?.chilkoot_retail_inventory) && {
              type: "divider",
            },
          webAppAccess?.downloads?.chilkoot_sales_report && {
            label: "Chilkoot Sales Report",
            key: "/downloads/chilkoot-sales-report",
            icon: (
              <IconReportMoney
                size={18}
                style={{ verticalAlign: "text-top" }}
              />
            ),
          },
          webAppAccess?.downloads?.chilkoot_retail_inventory && {
            label: "Chilkoot Retail Inventory",
            key: "/downloads/chilkoot-retail-inventory",
            icon: (
              <IconReportAnalytics
                size={18}
                style={{ verticalAlign: "text-top" }}
              />
            ),
          },
        ],
      },
    [ROLES.SysAdmin].includes(roles) && {
      label: (
        <Text style={{ fontSize: "1.1em", fontWeight: "bold" }}>ADMIN</Text>
      ),
      children: [
        {
          label: "Application Setting",
          key: "/admin/app-settings",
          icon: (
            <IconSettings size={18} style={{ verticalAlign: "text-top" }} />
          ),
        },
        {
          label: "User List",
          key: "/admin/users",
          icon: <IconUser size={18} style={{ verticalAlign: "text-top" }} />,
        },
        {
          label: "Site List",
          key: "/admin/sites",
          icon: (
            <IconLocation size={18} style={{ verticalAlign: "text-top" }} />
          ),
          reg: /\/admin\/sites\/\d+\/devices/gi,
        },
      ],
    },
    // {
    //   label: (
    //     <Text style={{ fontSize: "1.1em", fontWeight: "bold" }}>LOGOUT</Text>
    //   ),
    //   key: "nav-5",
    // },
  ];

  const loadProfile = async () => {
    try {
      setIsLoadingData(true);
      const resProfile = await axiosPrivate.get("/regular/profile");
      const { firstname, lastname, department, email, mobile } =
        resProfile.data;
      frmProfile.setFieldsValue({
        firstname,
        lastname,
        department,
        email,
        mobile,
      });
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
      setIsProfileModalOpen(false);
    } finally {
      setIsLoadingData(false);
    }
  };

  const updateProfileCallback = async (data) => {
    try {
      setIsSubmittingDate(true);
      const resProfile = await axiosPrivate.post("/regular/profile", {
        ...data,
      });
      notification.success({
        message: "Successfully updated profile.",
        placement: "bottom",
        duration: 4,
      });
      setIsProfileModalOpen(false);
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsSubmittingDate(false);
    }
  };

  const updateSecurityCallback = async (data) => {
    try {
      setIsSubmittingDate(true);
      const resSecurity = await axiosPrivate.post("/regular/security", {
        ...data,
      });
      setIsSecurityModalOpen(false);
      notification.success({
        message: "Successfully updated your password.",
        placement: "bottom",
        duration: 4,
      });
      await logout();
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsSubmittingDate(false);
    }
  };

  const signOut = async () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to sign out?",
      onOk: async () => {
        await logout();
        navigate("/");
      },
    });
  };

  return (
    <Header
      style={{
        backgroundColor: "rgb(252,252,252)",
        padding: 0,
        position: "sticky",
        width: "100%",
        right: 0,
        top: 0,
        zIndex: 100,
        // marginBottom: 18,
      }}
    >
      {contextHolderConfirm}
      <Flex
        wrap="wrap"
        align="center"
        justify={screenWidth > BreakPoint.lg ? "flex-end" : "space-between"}
        style={{ height: 64 }}
      >
        <Space>
          <Dropdown
            trigger={["click"]}
            menu={{
              selectedKeys: [selectedMenuKey],
              items: mobileMenuItems,
              selectable: true,
              onSelect: (info) => {
                const { key } = info;
                onMobileMenuClick(key);
              },
            }}
          >
            <Button
              type="text"
              icon={<MenuOutlined />}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                display: screenWidth > BreakPoint.lg ? "none" : "",
              }}
            />
          </Dropdown>
          {screenWidth <= BreakPoint.lg && (
            <Image
              style={{
                objectFit: "contain",
                maxHeight: 45,
                border: "1px solid lightgrey",
                borderRadius: 5,
              }}
              preview={false}
              src={`${baseUrl}/site_logo.png`}
            />
          )}
        </Space>
        {screenWidth > BreakPoint.lg && (
          <Menu
            style={{ marginLeft: 5, flex: 1 }}
            mode="horizontal"
            items={items}
            selectedKeys={selectedMenuKey}
            onSelect={(info) => {
              const { key, keyPath } = info;
              navigate(key);
            }}
          ></Menu>
        )}
        <Dropdown
          menu={{
            items: [
              {
                key: "nav-profile-basic",
                label: "Profile",
                icon: <UserOutlined />,
                onClick: async (info) => {
                  setIsProfileModalOpen(true);
                },
              },
              {
                key: "nav-profile-security",
                label: "Security",
                icon: <KeyOutlined />,
                onClick: () => {
                  setIsSecurityModalOpen(true);
                },
              },
              { type: "divider" },
              {
                key: "nav-profile-logout",
                label: "Logout",
                icon: <LogoutOutlined />,
                onClick: (info) => {
                  signOut();
                },
              },
            ],
          }}
        >
          <Avatar
            size="default"
            icon={<UserOutlined />}
            style={{ marginRight: 18 }}
          />
        </Dropdown>
      </Flex>
      <Modal
        title={`Security`}
        open={isSecurityModalOpen}
        onOk={() => {
          frmSecurity.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: isSubmittingData,
          loading: isSubmittingData,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setIsSecurityModalOpen(false);
        }}
        afterClose={() => {
          frmSecurity.resetFields();
        }}
        cancelButtonProps={{ disabled: isSubmittingData }}
        destroyOnClose
      >
        <Content>
          <Space direction="vertical">
            <Text style={{ color: "#cf1322" }}>
              By saving the new password, you will be automatically signed out,
              so do remember your new password.
            </Text>
          </Space>
          <Form
            style={{ marginTop: 10 }}
            form={frmSecurity}
            labelCol={{ span: 9, offset: 0 }}
            labelAlign="left"
            onFinish={updateSecurityCallback}
          >
            <Form.Item
              name="current_password"
              style={{ marginBottom: 10 }}
              label="Current Password"
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 20,
                  message:
                    "Current password cannot be blank or less than 3 characters.",
                },
              ]}
              hasFeedback
            >
              <Input.Password minLength={3} maxLength={20} required />
            </Form.Item>
            <Form.Item
              name="new_password"
              style={{ marginBottom: 10 }}
              label="New Password"
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 20,
                  message:
                    "New password cannot be blank or less than 3 characters.",
                },
              ]}
              hasFeedback
            >
              <Input.Password minLength={3} maxLength={20} required />
            </Form.Item>
            <Form.Item
              name="confirm_new_password"
              style={{ marginBottom: 10 }}
              label="Confirm New Password"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 20,
                  message:
                    "Confirm new password cannot be blank or less than 3 characters.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password minLength={3} maxLength={20} required />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <Modal
        title={`Profile`}
        open={isProfileModalOpen}
        afterOpenChange={async (open) => {
          if (open) {
            await loadProfile();
          }
        }}
        afterClose={() => {
          frmProfile.resetFields();
        }}
        onOk={() => {
          frmProfile.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: isSubmittingData || isLoadingData,
          loading: isSubmittingData || isLoadingData,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setIsProfileModalOpen(false);
        }}
        cancelButtonProps={{ disabled: isSubmittingData || isLoadingData }}
        destroyOnClose
      >
        <Content>
          <Form
            disabled={isSubmittingData || isLoadingData}
            style={{ marginTop: 10 }}
            form={frmProfile}
            labelCol={{ span: 9, offset: 0 }}
            labelAlign="left"
            onFinish={updateProfileCallback}
          >
            <Form.Item
              name="firstname"
              style={{ marginBottom: 10 }}
              label="First Name"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  max: 50,
                  message: "Maximum 50 characters allowed.",
                },
              ]}
              initialValue=""
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="lastname"
              style={{ marginBottom: 10 }}
              label="Last Name"
              rules={[
                {
                  type: "string",
                  max: 50,
                  message: "Maximum 50 characters allowed.",
                },
              ]}
              initialValue=""
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="department"
              style={{ marginBottom: 10 }}
              label="Department"
              initialValue=""
            >
              <Select
                options={[
                  {
                    value: "",
                    label: "Not Set",
                  },
                  {
                    value: "General Management",
                    label: "General Management",
                  },
                  {
                    value: "Marketing",
                    label: "Marketing",
                  },
                  { value: "Operations", label: "Operations" },
                  { value: "Finance", label: "Finance" },
                  { value: "Sales", label: "Sales" },
                  { value: "Human Resource", label: "Human Resource" },
                  { value: "Purchase", label: "Purchase" },
                  { value: "Business", label: "Business" },
                  { value: "Engineering", label: "Engineering" },
                  {
                    value: "Information Technology",
                    label: "Information Technology",
                  },
                  { value: "Legal", label: "Legal" },
                  { value: "Production", label: "Production" },
                  { value: "Risk Management", label: "Risk Management" },
                  { value: "Maintenance", label: "Maintenance" },
                  {
                    value: "Customer Service",
                    label: "Customer Service",
                  },
                  {
                    value: "Transportation",
                    label: "Transportation",
                  },
                  {
                    value: "Other",
                    label: "Other",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              initialValue=""
              name="email"
              style={{ marginBottom: 10 }}
              label="Email"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 5,
                  max: 100,
                  message: "Minimum 5 and maximum 100 characters allowed.",
                },
              ]}
            >
              <Input required minLength={5} maxLength={100} showCount />
            </Form.Item>
            <Form.Item
              initialValue=""
              name="mobile"
              style={{ marginBottom: 10 }}
              label="Phone"
              rules={[
                {
                  type: "string",
                  max: 50,
                  message: "Maximum 50 characters allowed.",
                },
              ]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </Header>
  );
};

export default SiteHeader;
