import React, { useState, useEffect } from "react";
import { Modal, Tooltip, Table, notification, Progress } from "antd";
import useAxiosPrivate from "../../../context/hooks/useAxiosPrivate";
import { AxiosError } from "axios";
import numberFormatter from "number-formatter";
import { useInterval } from "react-interval-hook";
import moment from "moment";
import isNumber from "isnumber";
import {
  IconCheck,
  IconAlertTriangle,
  IconHourglass,
  IconLoader,
  IconDownload,
  IconQuestionMark,
  IconRotateClockwise2,
} from "@tabler/icons-react";

const DownloadFleetCards = ({ open, onClose }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolderConfirm] = Modal.useModal();
  const { start, stop, isActive } = useInterval(
    async () => {
      await refreshJobStatus();
    },
    1000 * 5,
    { autoStart: false, immediate: false }
  );

  const addSiteToDownloadFleetCard = async (site) => {
    try {
      const _site = sites?.find((row) => row?.site_name === site?.site_name);
      _site.status = success ? "active" : "error";
      _site.last_finished = null;
      _site.message = message;
      _site.progress = null;

      setSites([...sites]);

      const { data } = await axiosPrivate.post(
        `/admin/fleetcard-toolbox/download-fleetcards`,
        { site_names: [site?.site_name] }
      );

      const { success, message } = data;
      if (!success) {
        _site.status = "error";
        setSites([...sites]);
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    }
  };

  const refreshJobStatus = async () => {
    try {
      const { data } = await axiosPrivate.get(
        "/admin/fleetcard-toolbox/download-fleetcards/get-status",
        { timeout: 1000 * 3 }
      );

      const { success, jobs } = data;
      if (success) {
        setSites(
          sites?.map((site) => ({
            ...site,
            status: success
              ? jobs?.find((job) => job?.data?.site_name === site?.site_name)
                  ?.state ?? ""
              : "unknown",
            message:
              jobs?.find((job) => job?.data?.site_name === site?.site_name)
                ?.failedReason ?? "",
            card_downloaded: success
              ? jobs?.find((job) => job?.data?.site_name === site?.site_name)
                  ?.returnvalue?.downloads?.length ?? ""
              : "",
            progress: success
              ? jobs?.find((job) => job?.data?.site_name === site?.site_name)
                  ?.progress ?? {}
              : {},
            last_finished: jobs
              ? jobs?.find((job) => job?.data?.site_name === site?.site_name)
                  ?.finishedOn ?? ""
              : "",
          }))
        );
      }
    } catch (err) {
      // notification.error({
      //   message:
      //     err instanceof AxiosError
      //       ? err?.response?.data ?? err?.message
      //       : err?.message ?? err?.toString(),
      //   placement: "bottom",
      //   duration: 4,
      // });
    } finally {
    }
  };

  useEffect(() => {
    return stop();
  }, []);

  useEffect(() => {
    if (!isActive() && sites?.length) {
      start();
    }
  }, [sites]);

  return (
    <>
      {contextHolderConfirm}
      <Modal
        closable={false}
        maskClosable={false}
        keyboard={false}
        style={{ minWidth: 500 }}
        styles={{
          body: {
            maxHeight: 550,
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        width={800}
        open={open}
        title={"Download Fleet Cards"}
        destroyOnClose
        onClose={(e) => {
          onClose();
        }}
        onOk={() => {
          onClose();
        }}
        okText={"Close"}
        okButtonProps={{
          disabled: isLoading,
        }}
        cancelText={"Cancel"}
        cancelButtonProps={{
          hidden: true,
          disabled: true,
        }}
        afterOpenChange={async (open) => {
          if (open) {
            try {
              setIsLoading(true);
              const { data } = await axiosPrivate.get(`/regular/api/get-sites`);
              const { data: dataStatus } = await axiosPrivate.get(
                "/admin/fleetcard-toolbox/download-fleetcards/get-status"
              );

              const { success, sites } = data;
              const { success: successJobStatus, jobs } = dataStatus;
              // console.log(jobs);
              if (success) {
                setSites(
                  sites
                    ?.filter(
                      (site) =>
                        ["infonet", "petrovend-fsc3000", "system 2"].includes(
                          site?.sitehdtype
                        ) &&
                        site?.skipped === "No" &&
                        site?.list_by_fleetcard_manager
                    )
                    ?.map((site) => ({
                      id: site?.id,
                      site_name: site?.sitename,
                      sitehdtype: site?.sitehdtype,
                      progress: successJobStatus
                        ? jobs?.find(
                            (job) => job?.data?.site_name === site?.sitename
                          )?.progress ?? {}
                        : {},
                      last_finished: successJobStatus
                        ? jobs?.find(
                            (job) => job?.data?.site_name === site?.sitename
                          )?.finishedOn ?? ""
                        : "",
                      status: successJobStatus
                        ? jobs?.find(
                            (job) => job?.data?.site_name === site?.sitename
                          )?.state ?? ""
                        : "unknown",
                      card_downloaded: successJobStatus
                        ? jobs?.find(
                            (job) => job?.data?.site_name === site?.sitename
                          )?.returnvalue?.downloads?.length ?? ""
                        : "",
                    }))
                );
              } else {
                notification.error({
                  message: "Failed loading site list.",
                  placement: "bottom",
                  duration: 4,
                });
              }
            } catch (err) {
              notification.error({
                message:
                  err instanceof AxiosError
                    ? err?.response?.data ?? err?.message
                    : err?.message ?? err?.toString(),
                placement: "bottom",
                duration: 4,
              });
            } finally {
              setIsLoading(false);
            }
          }
        }}
        afterClose={() => {
          stop();
          // setSites([]);
        }}
      >
        <Table
          loading={isDownloadInProgress || isLoading}
          size="middle"
          pagination={{ position: ["none", "none"], defaultPageSize: 25 }}
          bordered
          columns={[
            { dataIndex: "site_name", title: "Site Name", align: "left" },
            {
              dataIndex: "sitehdtype",
              title: "Technology",
              align: "center",
              responsive: ["md"],
            },
            {
              dataIndex: "card_downloaded",
              title: "Total #",
              align: "right",
              width: 80,
              render: (value, record, index) => {
                // console.log(record?.last_finished);
                return record?.status === "completed"
                  ? isNumber(value)
                    ? numberFormatter("###,###,###,##0.", value)
                    : ""
                  : "";
              },
            },
            {
              dataIndex: "last_finished",
              title: "Last Finished",
              align: "center",
              width: 150,
              render: (value, record, index) => {
                //console.log(record?.last_finished);
                return record?.last_finished
                  ? moment
                      .unix(record?.last_finished.toString().substring(0, 10))
                      .format("MM-DD HH:mm")
                  : "";
              },
              responsive: ["md"],
            },
            {
              dataIndex: "status",
              title: "Status",
              align: "center",
              width: 80,
              render: (value, record, index) => {
                if (value === "pending") {
                  return <IconHourglass stroke={1.5} size={20} />;
                } else if (value === "active") {
                  if (!record?.progress) {
                    return (
                      <IconLoader
                        stroke={1.5}
                        size={20}
                        color="blue"
                        className="icon-spin"
                      />
                    );
                  } else {
                    const { status, processed, total } = record?.progress;
                    if (isNumber(processed) && isNumber(total)) {
                      return (
                        <Tooltip
                          title={numberFormatter(
                            "##0.##%",
                            (processed / total) * 100
                          )}
                        >
                          <Progress
                            steps={5}
                            percent={((processed / total) * 100).toFixed(2)}
                            size={[10, 20]}
                            showInfo={false}
                          />
                        </Tooltip>
                      );
                      // return numberFormatter(
                      //   "##0.##%",
                      //   (processed / total) * 100
                      // );
                    } else {
                      return (
                        <IconLoader
                          stroke={1.5}
                          size={20}
                          color="blue"
                          className="icon-spin"
                        />
                      );
                    }
                  }
                } else if (value === "failed") {
                  return (
                    <Tooltip title={record?.message}>
                      <IconAlertTriangle
                        stroke={1.5}
                        size={20}
                        color="red"
                        style={{ cursor: "help" }}
                      />
                    </Tooltip>
                  );
                } else if (value === "completed") {
                  return <IconCheck stroke={1.5} size={20} color="darkgreen" />;
                } else if (value === "auditing") {
                  return (
                    <IconRotateClockwise2
                      stroke={1.5}
                      size={20}
                      color="lightblue"
                      className="icon-spin"
                    />
                  );
                } else if (value === "unknown") {
                  return (
                    <IconQuestionMark stroke={1.5} size={20} color="grey" />
                  );
                }
              },
            },
            {
              title: "Action",
              width: 80,
              key: "operation",
              align: "center",
              render: (text, record, index) => {
                if (
                  [
                    "completed",
                    "auditing",
                    "pending",
                    "failed",
                    "unknown",
                    "",
                  ].includes(record?.status)
                ) {
                  return (
                    <IconDownload
                      size={20}
                      stroke={1.5}
                      color="darkgreen"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        await addSiteToDownloadFleetCard(record);
                      }}
                    />
                  );
                } else if (["active"].includes(record?.status)) {
                  return (
                    <IconDownload size={20} stroke={1.5} color="lightgrey" />
                  );
                }
              },
            },
          ]}
          dataSource={sites}
          rowKey={(row) => row?.id}
        />
      </Modal>
    </>
  );
};

export default DownloadFleetCards;
