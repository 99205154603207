import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import scrollIntoView from "scroll-into-view";
import qs from "qs";
import useAuth from "../../context/hooks/useAuth";
import dayjs from "dayjs";
import { IconCirclePlus, IconCopy, IconReload } from "@tabler/icons-react";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import {
  Layout,
  Divider,
  Form,
  Table,
  Button,
  Input,
  InputNumber,
  ColorPicker,
  Checkbox,
  Modal,
  Space,
  Select,
  notification,
  Typography,
  Breadcrumb,
  Radio,
  DatePicker,
  Tooltip,
  Pagination,
} from "antd";
import { AggregationColor } from "antd/es/color-picker/color";
// import { Color } from "antd/es/color-picker";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-json";

import ModuleAccess from "../../Components/ModuleAccess";
import breakpoint from "../../Components/breakpoint";

const { Content } = Layout;
const { Search } = Input;
const { Title } = Typography;

const AdminSites = () => {
  const { auth } = useAuth();
  const [sites, setSites] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasInitialised, setHasInitialised] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [jsonErrors, setJsonErrors] = useState([]);
  const [sitenames, setSitenames] = useState([]);
  const [currentSiteId, setCurrentSiteId] = useState(null);
  const [seachUsersStatus, setSearchUsersStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [frmSite] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();
  const [modal, contextHolderConfirm] = Modal.useModal();
  const defaultPageSize = 25;
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["none", "none"],
      current: 1,
      pageSize: defaultPageSize,
    },
  });

  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }

  const columns = [
    {
      title: "Site Name",
      dataIndex: "sitename",
      align: "left",
      defaultSortOrder: "ascend",
      minWidth: 200,
      sorter: (a, b) => {
        return a.sitename > b.sitename;
      },
      render: (text, record, index) => {
        return <Link to={`/admin/sites/${record?.id}/devices`}>{text}</Link>;
      },
    },
    {
      title: "Site Code",
      dataIndex: "sitecode",
      align: "center",
      width: 150,
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => {
      //   return a.display_name > b.display_name;
      // },
    },
    {
      title: "Comm Mode",
      dataIndex: "commmode",
      align: "center",
      width: 130,
      responsive: ["xxl"],
    },
    {
      title: "Vendor",
      dataIndex: "sitehdtype",
      align: "center",
      width: 130,
      responsive: ["xxl"],
      render: (text, record, index) => {
        if (text?.toLowerCase().includes("infonet")) {
          return "infoNET";
        } else if (
          ["system 2", "petrovend", "petrovend-fsc3000"].includes(
            text?.toLowerCase()
          )
        ) {
          return "PetroVend";
        } else {
          return "";
        }
      },
    },
    {
      title: "Monitor",
      dataIndex: "monitor",
      align: "center",
      width: 100,
      responsive: ["xl"],
    },
    {
      title: "Skipped",
      dataIndex: "skipped",
      align: "center",
      width: 100,
    },
    // {
    //   title: "Roles",
    //   dataIndex: "roles",
    //   align: "center",
    //   width: 200,
    // },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link
              onClick={() => {
                handlerShowDetail(record.id);
              }}
            >
              <EditFilled style={{ fontSize: 15, color: "#4096ff" }} />
            </Link>
            <Link
              onClick={() => {
                handlerDelete(record.id, record.sitename);
              }}
            >
              <DeleteFilled style={{ fontSize: 15, color: "#cf1322" }} />
            </Link>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        setSearchKey(searchTerm);
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const hasInit = useRef(false);
  useEffect(() => {
    if (!hasInit.current || searchKey !== null || hasInitialised) {
      (async () => {
        await loadSites();
        hasInit.current = true;
      })();
    }
  }, [seachUsersStatus, searchKey, tableParams.pagination.current]);

  const handlerSearchUserDisabledChanged = async (status) => {
    setSearchUsersStatus(status);
    hasInit.current = false;
  };

  const loadSites = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/admin/sites?q=${encodeURIComponent(
          searchKey ?? ""
        )}&d=${encodeURIComponent(seachUsersStatus)}&${qs.stringify(
          tableParams
        )}`
      );

      const { sites, total_count } = data;
      setSites(sites);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total_count,
        },
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
      setHasInitialised(true);
      scrollToTopOfTable();
    }
  };

  const handlerSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlerShowForm = () => {
    setFormAction("add");
    setIsModalOpen(true);
  };

  const handlerCloneSite = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/admin/site/${selectedRow?.id}`);
      const {
        sitename,
        accesstoken,
        skipped,
        list_by_fleetcard_manager,
        site_type,
        commmode,
        category,
        sitecode,
        store_code,
        downloaddeposit,
        deposittag,
        depositaccount,
        depositscope,
        transscope,
        stockscope,
        misc_entry,
        export_format,
        productformasking,
        maskingname,
        maskingstartdate,
        maskingenddate,
        tankmanagement,
        tankm_protocol,
        tankm_url,
        tankm_username,
        tankm_password,
        tankm_skybitz_location,
        tankm_title,
        tankm_regularcolor,
        tankm_premiumcolor,
        tankm_dieselcolor,
        tankm_defcolor,
        tankm_tankwarningsettings,
        tankm_lowpercentage,
        tankm_lowlowpercentage,
        tankm_percentagewin,
        testsite,
        inventory_monitor,
        inventory_email_extra_low,
        monitor,
        guardians_enabled,
        smartrouter,
        customservice,
        sitehdtype,
        use_vpnip_by_fleetcard_manager,
        vpnip,
        siteip,
        siteport,
        fallback_site_name,
        petrovend_mode,
        petrovend_product_restriction,
        petrovend_quantity_restriction,
        petrovend_pump_restriction,
        app_sales_report,
      } = data;

      frmSite?.setFieldsValue({
        sitename: "",
        accesstoken,
        skipped,
        list_by_fleetcard_manager,
        site_type,
        commmode,
        category,
        sitecode: "",
        store_code: "",
        downloaddeposit,
        deposittag,
        depositaccount,
        depositscope: depositscope?.split(","),
        transscope: transscope?.split(","),
        stockscope: stockscope?.split(","),
        misc_entry: misc_entry ? true : false,
        export_format,
        productformasking,
        maskingname,
        maskingstartdate: dayjs(maskingstartdate, "YYYY-MM-DD"),
        maskingenddate: dayjs(maskingenddate, "YYYY-MM-DD"),
        tankmanagement,
        tankm_protocol,
        tankm_url,
        tankm_username,
        tankm_password,
        tankm_skybitz_location,
        tankm_title,
        tankm_regularcolor,
        tankm_premiumcolor,
        tankm_dieselcolor,
        tankm_defcolor,
        tankm_tankwarningsettings,
        tankm_lowpercentage,
        tankm_lowlowpercentage,
        tankm_percentagewin: tankm_percentagewin ? true : false,
        testsite,
        inventory_monitor: inventory_monitor === "Yes" ? true : false,
        inventory_email_extra_low:
          inventory_email_extra_low === "Yes" ? true : false,
        monitor,
        guardians_enabled,
        smartrouter,
        customservice,
        sitehdtype,
        use_vpnip_by_fleetcard_manager: use_vpnip_by_fleetcard_manager
          ? true
          : false,
        vpnip,
        siteip,
        siteport,
        fallback_site_name,
        petrovend_mode,
        petrovend_product_restriction,
        petrovend_quantity_restriction,
        petrovend_pump_restriction,
        app_sales_report,
      });

      setFormAction("add");
      setCurrentSiteId(selectedRow?.id);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    frmSite?.resetFields();
  };

  const handlerShowDetail = async (site_id) => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/admin/site/${site_id}`);
      const {
        sitename,
        accesstoken,
        skipped,
        list_by_fleetcard_manager,
        site_type,
        commmode,
        category,
        sitecode,
        store_code,
        downloaddeposit,
        deposittag,
        depositaccount,
        depositscope,
        transscope,
        stockscope,
        misc_entry,
        export_format,
        productformasking,
        maskingname,
        maskingstartdate,
        maskingenddate,
        tankmanagement,
        tankm_protocol,
        tankm_url,
        tankm_username,
        tankm_password,
        tankm_skybitz_location,
        tankm_title,
        tankm_regularcolor,
        tankm_premiumcolor,
        tankm_dieselcolor,
        tankm_defcolor,
        tankm_tankwarningsettings,
        tankm_lowpercentage,
        tankm_lowlowpercentage,
        tankm_percentagewin,
        testsite,
        inventory_monitor,
        inventory_email_extra_low,
        monitor,
        guardians_enabled,
        smartrouter,
        customservice,
        sitehdtype,
        vpnip,
        use_vpnip_by_fleetcard_manager,
        siteip,
        siteport,
        fallback_site_name,
        petrovend_mode,
        petrovend_product_restriction,
        petrovend_quantity_restriction,
        petrovend_pump_restriction,
        app_sales_report,
        fleetcard_config_json,
      } = data;
      frmSite?.setFieldsValue({
        sitename,
        accesstoken,
        skipped,
        list_by_fleetcard_manager,
        site_type,
        commmode,
        category,
        sitecode,
        store_code,
        downloaddeposit: downloaddeposit === "Yes" ? true : false,
        deposittag,
        depositaccount,
        depositscope: depositscope?.split(","),
        transscope: transscope?.split(","),
        stockscope: stockscope?.split(","),
        misc_entry: misc_entry ? true : false,
        export_format,
        productformasking,
        maskingname,
        maskingstartdate: dayjs(maskingstartdate).isValid()
          ? dayjs(maskingstartdate, "YYYY-MM-DD")
          : "",
        maskingenddate: dayjs(maskingenddate).isValid()
          ? dayjs(maskingenddate, "YYYY-MM-DD")
          : "",
        tankmanagement,
        tankm_protocol,
        tankm_url,
        tankm_username,
        tankm_password,
        tankm_skybitz_location,
        tankm_title,
        tankm_regularcolor,
        tankm_premiumcolor,
        tankm_dieselcolor,
        tankm_defcolor,
        tankm_tankwarningsettings,
        tankm_lowpercentage,
        tankm_lowlowpercentage,
        tankm_percentagewin: tankm_percentagewin ? true : false,
        testsite,
        inventory_monitor: inventory_monitor === "Yes" ? true : false,
        inventory_email_extra_low:
          inventory_email_extra_low === "Yes" ? true : false,
        monitor,
        guardians_enabled,
        smartrouter,
        customservice,
        sitehdtype,
        use_vpnip_by_fleetcard_manager: use_vpnip_by_fleetcard_manager
          ? true
          : false,
        vpnip,
        siteip,
        siteport,
        fallback_site_name,
        petrovend_mode,
        petrovend_product_restriction,
        petrovend_quantity_restriction,
        petrovend_pump_restriction,
        app_sales_report,
        fleetcard_config_json,
      });

      setFormAction("edit");
      setCurrentSiteId(site_id);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerDelete = async (site_id, site_name) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete site "${site_name}"?`,
      onOk: async () => {
        try {
          setSubmitting(true);
          await axiosPrivate.delete(`/admin/site/${site_id}`);
          notification.success({
            message: `Successfully deleted site "${site_name}."`,
            placement: "bottom",
            duration: 4,
          });
          setSites((state) => {
            const new_state = state.filter((m) => m.id !== site_id);
            return [...new_state];
          });
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleFormSubmit = async (data) => {
    try {
      setSubmitting(true);
      if (formAction === "add") {
        const res_add = await axiosPrivate.post(`/admin/sites/`, {
          ...data,
          maskingstartdate: dayjs(data?.maskingstartdate).isValid()
            ? data?.maskingstartdate?.format("YYYY-MM-DD")
            : "",
          maskingenddate: dayjs(data?.maskingenddate).isValid()
            ? data?.maskingenddate?.format("YYYY-MM-DD")
            : "",
          misc_entry: data?.misc_entry ? 1 : 0,
          tankm_percentagewin: data?.tankm_percentagewin ? 1 : 0,
          inventory_email_extra_low: data?.inventory_email_extra_low
            ? "Yes"
            : "No",
          inventory_monitor: data?.inventory_monitor ? "Yes" : "No",
          downloaddeposit: data?.downloaddeposit ? "Yes" : "No",
          depositscope: data?.depositscope?.join(","),
          stockscope: data?.stockscope?.join(","),
          transscope: data?.transscope?.join(","),
          // tankm_regularcolor:
          //   data.tankm_regularcolor instanceof Color
          //     ? data.tankm_regularcolor.toHexString()
          //     : data.tankm_regularcolor,
          // tankm_premiumcolor:
          //   data.tankm_premiumcolor instanceof Color
          //     ? data.tankm_premiumcolor.toHexString()
          //     : data.tankm_premiumcolor,
          // tankm_dieselcolor:
          //   data.tankm_dieselcolor instanceof Color
          //     ? data.tankm_dieselcolor.toHexString()
          //     : data.tankm_dieselcolor,
          // tankm_defcolor:
          //   data.tankm_defcolor instanceof Color
          //     ? data.tankm_defcolor.toHexString()
          //     : data.tankm_defcolor,
        });
        notification.success({
          message: `Successfully created new site ${data?.sitename}.`,
          placement: "bottom",
          duration: 4,
        });
        setSites((state) => {
          return [...state, { ...res_add.data }];
        });
      } else if (formAction === "edit") {
        const { data: resPatchSite } = await axiosPrivate.patch(
          `/admin/site/${currentSiteId}`,
          {
            ...data,
            maskingstartdate: dayjs(data?.maskingstartdate).isValid()
              ? data?.maskingstartdate?.format("YYYY-MM-DD")
              : "",
            maskingenddate: dayjs(data?.maskingenddate).isValid()
              ? data?.maskingenddate?.format("YYYY-MM-DD")
              : "",
            misc_entry: data?.misc_entry ? 1 : 0,
            tankm_percentagewin: data?.tankm_percentagewin ? 1 : 0,
            inventory_email_extra_low: data?.inventory_email_extra_low
              ? "Yes"
              : "No",
            inventory_monitor: data?.inventory_monitor ? "Yes" : "No",
            downloaddeposit: data?.downloaddeposit ? "Yes" : "No",
            depositscope: data?.depositscope?.join(","),
            stockscope: data?.stockscope?.join(","),
            transscope: data?.transscope?.join(","),
            // tankm_regularcolor:
            //   data.tankm_regularcolor instanceof AggregationColor
            //     ? data.tankm_regularcolor.toHexString()
            //     : data.tankm_regularcolor,
            // tankm_premiumcolor:
            //   data.tankm_premiumcolor instanceof Color
            //     ? data.tankm_premiumcolor.toHexString()
            //     : data.tankm_premiumcolor,
            // tankm_dieselcolor:
            //   data.tankm_dieselcolor instanceof Color
            //     ? data.tankm_dieselcolor.toHexString()
            //     : data.tankm_dieselcolor,
            // tankm_defcolor:
            //   data.tankm_defcolor instanceof Color
            //     ? data.tankm_defcolor.toHexString()
            //     : data.tankm_defcolor,
          }
        );
        notification.success({
          message: `Successfully updated site ${data.sitename}.`,
          placement: "bottom",
          duration: 4,
        });
        setSites((state) => {
          let found_site_index = state.findIndex(
            (site) => site.id === currentSiteId
          );
          state[found_site_index] = { ...data, id: resPatchSite?.id };
          return [...state];
        });
      }
      frmSite?.resetFields();
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: page,
        },
      };
    });
    if (pageSize !== tableParams.pagination?.pageSize) {
      setSites([]);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ ...tableParams, sorter, ...filters });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSites([]);
    }
    // refTable.current.scrollTo({ top: 1 });
  };

  const scrollToTopOfTable = () => {
    scrollIntoView(document.querySelector(".scroll-first-row"), {
      align: {
        // top: 0,
        topOffset: 0,
      },
    });
  };

  return (
    <Content
      className="admin-generic-content"
      // style={{ minWidth: 1, maxWidth: breakpoint.xxl, width: breakpoint.xxl }}
    >
      {contextHolderConfirm}
      <Modal
        title={formAction === "add" ? "Add New Site" : "Edit Site"}
        styles={{
          body: { maxHeight: 500, overflowX: "hidden", overflowY: "auto" },
        }}
        width={600}
        open={isModalOpen}
        onOk={() => {
          frmSite.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          htmlType: "submit",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: submitting }}
        destroyOnClose={true}
        afterOpenChange={async (open) => {
          if (open) {
            try {
              const { data } = await axiosPrivate.get(`/admin/sitenames/`);
              const { sitenames } = data ?? [];
              setSitenames(sitenames);
            } catch (err) {
            } finally {
            }
          }
        }}
      >
        <Content>
          <Form
            size="middle"
            form={frmSite}
            labelCol={{ span: 7, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            layout="horizontal"
            onFinish={handleFormSubmit}
            disabled={submitting}
            scrollToFirstError={true}
          >
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Basic Information
            </Divider>
            <Form.Item
              name="site_type"
              style={{ marginBottom: 10 }}
              label="Site Type"
              required
              initialValue={""}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 20,
                  whitespace: false,
                },
              ]}
            >
              <Select
                options={[
                  { value: "", label: "" },
                  { value: "retail", label: "Retail" },
                  { value: "bulkplant", label: "Bulk Plant" },
                  { value: "both", label: "Both" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="sitename"
              style={{ marginBottom: 10 }}
              label="Site Name"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 100,
                  min: 4,
                },
              ]}
            >
              <Input minLength={4} maxLength={100} showCount />
            </Form.Item>
            <Form.Item
              name="accesstoken"
              style={{ marginBottom: 10 }}
              label="Access Token"
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                  max: 255,
                  min: 10,
                },
              ]}
            >
              <Input.Password minLength={10} maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="category"
              style={{ marginBottom: 10 }}
              label="Ownership"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                },
              ]}
            >
              <Select
                options={[
                  { label: "North60 Owned", value: "north60" },
                  { label: "Affiliated", value: "customer" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="sitehdtype"
              style={{ marginBottom: 10 }}
              label="Technology"
              required
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                },
              ]}
            >
              <Select
                options={[
                  { label: "Not Applicable", value: "" },
                  { label: "Petrovend System 2", value: "system 2" },
                  { label: "Petrovend FSC3000", value: "petrovend-fsc3000" },
                  { label: "infoNet", value: "infonet" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="vpnip"
              style={{ marginBottom: 10 }}
              label="VPN IP"
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                  max: 50,
                },
              ]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="use_vpnip_by_fleetcard_manager"
              style={{ marginBottom: 10 }}
              label="Use VPN IP"
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="siteip"
              style={{ marginBottom: 10 }}
              label="Site IP"
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                  max: 50,
                },
              ]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="siteport"
              style={{ marginBottom: 10 }}
              label="Site Port"
              initialValue={0}
              required
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "",
                },
              ]}
            >
              <InputNumber max={65535} min={0} />
            </Form.Item>
            <Form.Item
              name="commmode"
              style={{ marginBottom: 10 }}
              label="Comm Mode"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                },
              ]}
              initialValue={"Cloud"}
            >
              <Select options={[{ label: "Cloud", value: "Cloud" }]} />
            </Form.Item>
            <Form.Item
              name="petrovend_mode"
              style={{ marginBottom: 10 }}
              label="Connector Mode"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                },
              ]}
              initialValue={"Server"}
            >
              <Select
                options={[
                  { label: "Server Mode", value: "Server" },
                  { label: "Client Mode", value: "Client" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="fallback_site_name"
              style={{ marginBottom: 10 }}
              label="Failover Site"
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                  max: 50,
                },
              ]}
            >
              <Select
                options={[
                  { label: "Not Set", value: "" },
                  ...sitenames?.map((site) => ({
                    label: site.sitename,
                    value: site.sitename,
                  })),
                ]}
              />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Transaction Settings
            </Divider>
            <Form.Item
              name="sitecode"
              style={{ marginBottom: 10 }}
              label="Site Code"
              initialValue={""}
              rules={[{ type: "string", max: 45 }]}
            >
              <Input maxLength={45} showCount />
            </Form.Item>
            <Form.Item
              name="store_code"
              style={{ marginBottom: 10 }}
              label="Store Code"
              initialValue={""}
              rules={[{ type: "string", max: 45 }]}
            >
              <Input maxLength={45} showCount />
            </Form.Item>
            <Form.Item
              name="transscope"
              style={{ marginBottom: 10 }}
              label="Scope"
              rules={[{ type: "array" }]}
              initialValue={[]}
              tooltip="What payment methods are included in Transaction Report"
            >
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                }}
                options={[
                  { label: "Cash", value: `'Cash'` },
                  { label: "Charge", value: `'Charge'` },
                  { label: "North60 Voucher", value: `'N60 Voucher'` },
                  { label: "American Express", value: `'American Express'` },
                  { label: "Master Card", value: `'MasterCard'` },
                  { label: "Visa", value: `'Visa'` },
                  { label: "Debit Card", value: `'Debit Card'` },
                  { label: "North60 Fleet Card", value: `'North Of 60 Fleet'` },
                  { label: "INTERAC", value: `'INTERAC'` },
                  {
                    label: "Yukon Government Card",
                    value: `'Yukon Territory Gov'`,
                  },
                  { label: "Lottery", value: `'Lottery'` },
                  { label: "US Dollars", value: `'US Dollars'` },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="stockscope"
              style={{ marginBottom: 10 }}
              label="Products"
              rules={[{ type: "array" }]}
              initialValue={[]}
              tooltip="What products are included in Transaction Report"
            >
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                }}
                options={[
                  { label: "Diesel", value: `'DIESEL'` },
                  { label: "MidGrade", value: `'MIDGRADE'` },
                  { label: "Premium", value: `'PREMIUM'` },
                  { label: "Regular", value: `'REGULAR'` },
                  { label: "Propane", value: `'PROPANE'` },
                  { label: "Prepay Refund", value: `'PrepayRefund'` },
                  { label: "Lottery Ticket", value: `'Lottery Ticket'` },
                  { label: "None", value: `'Non'` },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="export_format"
              style={{ marginBottom: 10 }}
              label="Export Format"
              rules={[{ type: "string", max: 50 }]}
              initialValue={"n/a"}
            >
              <Select
                options={[
                  { label: "n/a", value: "n/a" },
                  { label: "CSV", value: "csv" },
                  { label: "K2500", value: "k2500" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={"misc_entry"}
              style={{ marginBottom: 10 }}
              initialValue={false}
              valuePropName="checked"
              label="Misc. Entry"
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              name="productformasking"
              style={{ marginBottom: 10 }}
              label="Masking Product"
              rules={[{ type: "string", max: 50 }]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="maskingname"
              style={{ marginBottom: 10 }}
              label="Masking Name"
              rules={[{ type: "string", max: 50 }]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="maskingstartdate"
              style={{ marginBottom: 10 }}
              label="Masking from"
              rules={[{ type: "date" }]}
            >
              <DatePicker format={"MM-DD"} inputReadOnly />
            </Form.Item>
            <Form.Item
              name="maskingenddate"
              style={{ marginBottom: 10 }}
              label="Masking till"
              rules={[{ type: "date" }]}
            >
              <DatePicker format={"MM-DD"} inputReadOnly />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Deposits Settings
            </Divider>
            <Form.Item
              label="Download Deposit"
              name={"downloaddeposit"}
              style={{ marginBottom: 10 }}
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              label="Deposit Account"
              name={"depositaccount"}
              style={{ marginBottom: 10 }}
              initialValue={""}
              rules={[{ type: "string", max: 45 }]}
            >
              <Input max={45} showCount />
            </Form.Item>
            <Form.Item
              label="Deposit Tag"
              name={"deposittag"}
              style={{ marginBottom: 10 }}
              initialValue={""}
              rules={[{ type: "string", max: 45 }]}
            >
              <Input max={45} showCount />
            </Form.Item>
            <Form.Item
              label="Deposit Scope"
              name={"depositscope"}
              style={{ marginBottom: 10 }}
              rules={[{ type: "array" }]}
              initialValue={[]}
            >
              <Checkbox.Group
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                options={[
                  { label: "Cash Deposit", value: `Cash` },
                  { label: "Bank Deposit", value: `Deposit` },
                ]}
              />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Fleetcard Configuration
            </Divider>
            <Form.Item
              name="fleetcard_config_json"
              label="Fleetcard Config"
              style={{ marginBottom: 10 }}
              initialValue={`{
  "technology": "petrovend",
  "product_restriction": 0,
  "quantity_restriction": 0,
  "misc_entry": "N"
}`}
              rules={[
                {
                  validator: (_, value) => {
                    if (jsonErrors.length) {
                      return Promise.reject("Json syntax error.");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <AceEditor
                mode="json"
                theme="textmate"
                name="blah2"
                fontSize={14}
                lineHeight={19}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                enableBasicAutocompletion={false}
                enableLiveAutocompletion={false}
                enableSnippets={false}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: true }}
                onValidate={(annotations) => {
                  setJsonErrors(annotations);
                }}
                width="99%"
                style={{
                  height: "15em",
                  border: "1px solid rgb(216,216,216)",
                  borderRadius: 5,
                }}
              />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Inventory Monitoring Settings
            </Divider>
            <Form.Item
              name="tankmanagement"
              label="Inventory System"
              style={{ marginBottom: 10 }}
              initialValue={"Not Using"}
            >
              <Select
                options={[
                  { label: "Not Using", value: "Not Using" },
                  { label: "EVO 450", value: "EVO 450" },
                  { label: "EVO 550", value: "EVO 550" },
                  { label: "EVO 600", value: "EVO 600" },
                  { label: "SkyBitz", value: "SkyBitz" },
                  { label: "VEEDER ROOT TLS4", value: "VEEDER ROOT TLS4" },
                  { label: "ShadowCMS", value: "ShadowCMS" },
                  { label: "ATO WIN-911", value: "ATO WIN-911" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="tankm_protocol"
              label="Web Protocol"
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Select
                options={[
                  { label: "", value: "" },
                  { label: "HTTP", value: "HTTP" },
                  { label: "HTTPS", value: "HTTPS" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="tankm_url"
              style={{ marginBottom: 10 }}
              label="WEB URL"
              initialValue={""}
              rules={[{ type: "string", max: 255 }]}
            >
              <Input maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="tankm_username"
              style={{ marginBottom: 10 }}
              label="Login ID"
              initialValue={""}
              rules={[{ type: "string", max: 255 }]}
            >
              <Input maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="tankm_password"
              style={{ marginBottom: 10 }}
              label="Login Secret"
              initialValue={""}
              rules={[{ type: "string", max: 255 }]}
            >
              <Input.Password maxLength={255} />
            </Form.Item>
            <Form.Item
              name="tankm_skybitz_location"
              style={{ marginBottom: 10 }}
              label="Skybitz Location"
              initialValue={""}
              rules={[{ type: "string", max: 255 }]}
            >
              <Input maxLength={255} showCount />
            </Form.Item>
            <Form.Item
              name="inventory_monitor"
              label="Inventory Monitor"
              style={{ marginBottom: 10 }}
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              name="inventory_email_extra_low"
              label="LowLow Warning"
              style={{ marginBottom: 10 }}
              initialValue={false}
              valuePropName="checked"
              tooltip="Send email warning when lowlow warning occurs"
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              name="tankm_lowpercentage"
              label="Low Percent"
              style={{ marginBottom: 10 }}
              initialValue={40}
              rules={[{ required: true, type: "number", min: 0, max: 99 }]}
            >
              <InputNumber min={0} max={99} step={1} />
            </Form.Item>
            <Form.Item
              name="tankm_lowlowpercentage"
              label="LowLow Percent"
              style={{ marginBottom: 10 }}
              initialValue={20}
              rules={[{ required: true, type: "number", min: 0, max: 99 }]}
            >
              <InputNumber min={0} max={99} step={1} />
            </Form.Item>
            <Form.Item
              name="tankm_tankwarningsettings"
              label="Detail Setting"
              style={{ marginBottom: 10 }}
              initialValue={"{}"}
              rules={[
                {
                  validator: (_, value) => {
                    if (jsonErrors.length) {
                      return Promise.reject("Json syntax error.");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <AceEditor
                mode="json"
                theme="textmate"
                name="blah2"
                fontSize={14}
                lineHeight={19}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                enableBasicAutocompletion={false}
                enableLiveAutocompletion={false}
                enableSnippets={false}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: true }}
                onValidate={(annotations) => {
                  setJsonErrors(annotations);
                }}
                width="99%"
                style={{
                  height: "30em",
                  border: "1px solid rgb(216,216,216)",
                  borderRadius: 5,
                }}
              />
            </Form.Item>
            <Form.Item
              name="tankm_percentagewin"
              label="Use Percentage"
              style={{ marginBottom: 10 }}
              initialValue={true}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              N60 APP Settings
            </Divider>
            <Form.Item
              name="tankm_title"
              label="Tank Title"
              style={{ marginBottom: 10 }}
              initialValue={"Tank"}
              rules={[{ required: true, type: "string", max: 50 }]}
            >
              <Select
                options={[
                  { label: "Tank Name", value: "Tank" },
                  { label: "Tank Product", value: "Product" },
                  { label: "Both", value: "Both" },
                ]}
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10 }}
              name={"tankm_regularcolor"}
              label="Regular Gas"
              initialValue={"#c62828"}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker size="small" showText format="hex" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10 }}
              name={"tankm_premiumcolor"}
              label="Premium Gas"
              initialValue={"#d84315"}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker size="small" showText format="hex" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10 }}
              name={"tankm_dieselcolor"}
              label="Diesel"
              initialValue={"#2e7d32"}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker size="small" showText format="hex" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10 }}
              name={"tankm_defcolor"}
              label="DEF"
              initialValue={"#0277bd"}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker size="small" showText format="hex" />
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Other Settings
            </Divider>
            <Form.Item
              name="guardians_enabled"
              label="Guardians"
              style={{ marginBottom: 0 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="smartrouter"
              label="Smart Router"
              style={{ marginBottom: 0 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="monitor"
              label="Minotored"
              style={{ marginBottom: 0 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="skipped"
              label="Skipped"
              style={{ marginBottom: 0 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="list_by_fleetcard_manager"
              label="Site List Inc."
              style={{ marginBottom: 0 }}
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="testsite"
              label="Test Site"
              style={{ marginBottom: 0 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="customservice"
              label="Virtual Site"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
              tooltip="This site serves as virtual add-on providing no standard interfaces but customised ones"
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Site List
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Admin Control Panel",
            },

            {
              title: "Site List",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-header">
          <div className="admin-generic-toolbar">
            <Space size="small" wrap>
              <Search
                allowClear
                disabled={isLoading}
                variant="filled"
                size="middle"
                placeholder="Key in to search"
                onChange={handlerSearchChange}
              />
            </Space>
            <Space size="small" wrap>
              <Tooltip title="Reload">
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  size="middle"
                  icon={<IconReload />}
                  onClick={async () => {
                    await loadSites();
                  }}
                />
              </Tooltip>
              <Select
                disabled={isLoading}
                size="middle"
                style={{ minWidth: 160 }}
                onChange={handlerSearchUserDisabledChanged}
                defaultValue=""
                options={[
                  { value: "", label: "All Sites" },
                  { value: "Yes", label: "Skipped Sites" },
                ]}
              />{" "}
              <Tooltip title="Add Site">
                <Button
                  icon={<IconCirclePlus />}
                  size="middle"
                  type="primary"
                  onClick={() => {
                    handlerShowForm();
                  }}
                  disabled={isLoading}
                ></Button>
              </Tooltip>
              <Tooltip title="Clone Site">
                <Button
                  icon={<IconCopy />}
                  size="middle"
                  type="default"
                  onClick={() => {
                    handlerCloneSite();
                  }}
                  disabled={isLoading || selectedRow === null}
                ></Button>
              </Tooltip>
            </Space>
          </div>
        </div>
        <div className="admin-generic-card-body">
          <div className="admin-generic-table-wrap">
            <Table
              bordered
              style={{ minWidth: 800 }}
              sticky
              pagination={{
                position: ["none", "none"],
                defaultPageSize: defaultPageSize,
              }}
              columns={columns}
              rowKey={(site) => site.id}
              dataSource={sites}
              loading={isLoading}
              showSorterTooltip={false}
              rowClassName={(record, index) => {
                const classes = [];
                if (record.status === "Inactive") {
                  classes.push("disabled-record");
                }
                if (index <= 1) {
                  classes.push("scroll-first-row");
                }
                return classes.join(" ");
              }}
              scroll={{ scrollToFirstRowOnChange: true }}
              rowSelection={{
                hideSelectAll: true,
                onChange: (selectedKeys, selectedRows) => {
                  setSelectedRow(selectedRows?.length ? selectedRows[0] : null);
                },
                type: "radio",
              }}
              onChange={handleTableChange}
            />
          </div>
          <Content className="pagination">
            <Button
              size="middle"
              disabled={isLoading}
              loading={isLoading}
              icon={<IconReload />}
              onClick={async () => {
                await loadSites();
              }}
            />
            <Pagination
              size="middle"
              defaultCurrent={1}
              disabled={isLoading}
              showSizeChanger={false}
              current={tableParams.pagination.current}
              pageSize={defaultPageSize}
              defaultPageSize={tableParams.pagination.pageSize}
              total={tableParams.pagination?.total}
              onChange={handlePageChange}
              showQuickJumper
            />
          </Content>
        </div>
      </div>
    </Content>
  );
};

export default AdminSites;
