import React, { useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Layout, Menu, Image } from "antd";
import {
  IconCashBanknote,
  IconTransactionDollar,
  IconReportAnalytics,
  IconReportMoney,
  IconSettings,
  IconLocation,
  IconUser,
  IconHome,
  IconGasStation,
  IconBuildingFactory,
} from "@tabler/icons-react";
import { useWindowSize } from "@uidotdev/usehooks";
import jwt_decode from "jwt-decode";
import useAuth from "./context/hooks/useAuth";
import { baseUrl } from "./api/axios";

import "./App.css";
import RequireAccess from "./Components/RequireAccess";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";

import BreakPoint from "./Components/breakpoint";

import Page404 from "./Pages/404";
import Page403 from "./Pages/403";

import Login from "./Pages/Login";
import SiteHeader from "./Components/Header";
import SiteFooter from "./Components/Footer";
import Dashboard from "./Pages/Dashboard";

import RetailFleetCards from "./Pages/RetailFleetCards";
import BulkPlantFleetCards from "./Pages/BulkPlantFleetCards";
import SiteStatus from "./Components/SiteStatus";
import AdminUsers from "./Pages/Admin/Users";
import AdminSites from "./Pages/Admin/Sites";
import AdminDevices from "./Pages/Admin/Devices";
import ApplicationSettings from "./Pages/Admin/AppSettings";

import Deposits from "./Pages/Deposits";
import Transactions from "./Pages/Transactions";
import ChilkootRetailInventory from "./Pages/ChilkootRetailInventory";
import ChilkootSalesReport from "./Pages/ChilkootSalesReport";

import ROLES from "./Components/Role";
import ModuleAccess from "./Components/ModuleAccess";

import useSelectedMenuKeys from "./context/hooks/useSelectedMenuKeys";

const { Sider, Content } = Layout;

const siderCollapsed =
  window.localStorage.getItem("ts-webapp-sider") === "true" ? true : false;

function App() {
  const { auth } = useAuth();
  const decoded = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined;
  const roles = decoded?.UserInfo?.roles || "";
  const web_app_access = decoded?.UserInfo?.web_app_access || {};

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/403" element={<Page403 />}></Route>
      <Route path="/404" element={<Page404 />}></Route>
      <Route
        path="*"
        element={<WebFrame userRole={roles} webAppAccess={web_app_access} />}
      ></Route>
    </Routes>
  );
}

function WebFrame({ userRole, webAppAccess }) {
  const { width: screenWidth } = useWindowSize();

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(siderCollapsed);

  const mainMenus = [
    {
      label: "Dashboard",
      key: "/",
      icon: <IconHome size={20} />,
    },
    [ROLES.User, ROLES.Operator, ROLES.Admin, ROLES.SysAdmin].includes(
      userRole
    ) &&
      webAppAccess?.cards?.access &&
      (webAppAccess?.cards?.retail_fleet_card ||
        webAppAccess?.cards?.bulkplant_fleet_card) && {
        label: "Fleetcards",
        type: "group",
        children: [
          webAppAccess?.cards?.retail_fleet_card && {
            label: "Retail Cards",
            key: "/fleet-cards/retail",
            icon: <IconGasStation size={20} />,
          },
          webAppAccess?.cards?.bulkplant_fleet_card && {
            label: "Bulk Plant Cards",
            key: "/fleet-cards/bulkplant",
            icon: <IconBuildingFactory size={20} />,
          },
        ],
      },
    [ROLES.User, ROLES.Operator, ROLES.Admin, ROLES.SysAdmin].includes(
      userRole
    ) &&
      webAppAccess?.downloads?.access &&
      (webAppAccess?.downloads?.deposits ||
        webAppAccess?.downloads?.transactions ||
        webAppAccess?.downloads?.chilkoot_retail_inventory ||
        webAppAccess?.downloads?.chilkoot_sales_report) && {
        label: "Downloads",
        type: "group",
        children: [
          webAppAccess?.downloads?.deposits && {
            label: "Deposits",
            key: "/downloads/deposits",
            icon: <IconCashBanknote size={20} />,
          },
          webAppAccess?.downloads?.transactions && {
            label: "Transactions",
            key: "/downloads/transactions",
            icon: <IconTransactionDollar size={20} />,
          },
          webAppAccess?.downloads?.chilkoot_sales_report && {
            label: "Chilkoot Sales Report",
            key: "/downloads/chilkoot-sales-report",
            icon: <IconReportMoney size={20} />,
          },
          webAppAccess?.downloads?.chilkoot_retail_inventory && {
            label: "Chilkoot Retail Inventory",
            key: "/downloads/chilkoot-retail-inventory",
            icon: <IconReportAnalytics size={20} />,
          },
        ],
      },
    [ROLES.Admin, ROLES.SysAdmin].includes(userRole) && {
      label: "Admin Panel",
      type: "group",
      children: [
        {
          label: "Application Setting",
          key: "/admin/app-settings",
          icon: <IconSettings size={20} />,
        },
        {
          label: "User List",
          key: "/admin/users",
          icon: <IconUser size={20} />,
        },
        {
          label: "Site List",
          key: "/admin/sites",
          icon: <IconLocation size={20} />,
          reg: /\/admin\/sites\/\d+\/devices/gi,
        },
      ],
    },
  ];

  const selectedNavigationKeys = useSelectedMenuKeys({ mainMenus });

  return (
    <Layout
      style={{
        margin: 0,
        padding: 0,
        height: "100vh",
        maxHeight: "100vh",
      }}
    >
      {screenWidth > BreakPoint.lg && (
        <Sider
          theme="dark"
          //breakpoint="lg"
          collapsible={screenWidth > BreakPoint.lg ? true : false}
          width={240}
          collapsed={collapsed}
          onBreakpoint={(broken) => {
            // setCollapsed(broken);
          }}
          onCollapse={(collapsed, type) => {
            setCollapsed(collapsed);
            window.localStorage.setItem("ts-webapp-sider", collapsed);
          }}
        >
          <Content
            style={{
              padding: 5,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: collapsed ? 10 : 60,
              marginRight: collapsed ? 10 : 60,
              height: collapsed ? 64 : 110,
              maxHeight: collapsed ? 64 : 110,
              backgroundColor: "rgba(255, 255, 255, .2)",
              borderRadius: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                objectFit: "contain",
                maxWidth: 200,
                maxHeight: collapsed ? 55 : 100,
              }}
              preview={false}
              src={`${baseUrl}/site_logo.png`}
            />
          </Content>
          <Content className="main-nav-menu">
            <Menu
              theme="dark"
              mode="inline"
              style={{
                marginTop: 20,
              }}
              onSelect={(info) => {
                const { key, keyPath } = info;
                navigate(key);
              }}
              items={mainMenus}
              selectedKeys={selectedNavigationKeys}
            />
          </Content>
        </Sider>
      )}
      <Layout className="main-content">
        <SiteHeader
          mobileMenuItems={mainMenus}
          selectedMenuKey={selectedNavigationKeys}
          onMobileMenuClick={(key, keyPath) => {
            navigate(key);
          }}
          webAppAccess={webAppAccess}
          userRole={userRole}
        />
        <div
          className="flex-row-fluid justify-start site-background"
          style={{ paddingTop: 10 }}
        >
          <MainContent />
        </div>
        <SiteFooter />
      </Layout>
    </Layout>
  );
}

function MainContent() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route element={<SiteStatus />}>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.User,
                  ROLES.Operator,
                  ROLES.Admin,
                  ROLES.SysAdmin,
                ]}
              />
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Navigate to={"/"} />} />
            <Route
              element={<RequireAccess requiredAccess={"retail_fleet_card"} />}
            >
              <Route
                path="/fleet-cards/retail"
                element={<RetailFleetCards />}
              />
            </Route>
            <Route
              element={
                <RequireAccess requiredAccess={"bulkplant_fleet_card"} />
              }
            >
              <Route
                path="/fleet-cards/bulkplant"
                element={<BulkPlantFleetCards />}
              />
            </Route>
            <Route element={<RequireAccess requiredAccess={"deposits"} />}>
              <Route path="/downloads/deposits" element={<Deposits />} />
            </Route>
            <Route element={<RequireAccess requiredAccess={"transactions"} />}>
              <Route
                path="/downloads/transactions"
                element={<Transactions />}
              />
            </Route>
            <Route
              element={
                <RequireAccess requiredAccess={"chilkoot_retail_inventory"} />
              }
            >
              <Route
                path="/downloads/chilkoot-retail-inventory"
                element={<ChilkootRetailInventory />}
              />
            </Route>
            <Route
              element={
                <RequireAccess requiredAccess={"chilkoot_sales_report"} />
              }
            >
              <Route
                path="/downloads/chilkoot-sales-report"
                element={<ChilkootSalesReport />}
              />
            </Route>
          </Route>
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SysAdmin]} />}>
          <Route path="/admin/app-settings" element={<ApplicationSettings />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/sites" element={<AdminSites />} />
          <Route
            path="/admin/sites/:site_id/devices"
            element={<AdminDevices />}
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/404" />}></Route>
    </Routes>
  );
}

export default App;
