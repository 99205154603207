import React, { useState, useEffect } from "react";
import { Button, Space, Typography, Progress, notification, Modal } from "antd";
import useAxiosPrivate from "../../../context/hooks/useAxiosPrivate";
import { AxiosError } from "axios";
import { useInterval } from "react-interval-hook";
const { Text } = Typography;

const InvokeUpdateCachedStatistics = () => {
  const axiosPrivate = useAxiosPrivate();
  const [jobState, setJobState] = useState({});
  const [modal, contextHolderConfirm] = Modal.useModal();
  const { start, stop, isActive } = useInterval(
    async () => {
      await refreshJobStatus();
    },
    1000 * 5,
    { autoStart: false, immediate: false }
  );

  useEffect(() => {
    if (!isActive()) {
      start();
    }
  }, []);

  useEffect(() => {
    if (isActive()) {
      return () => {
        stop();
      };
    }
  }, []);

  const refreshJobStatus = async () => {
    try {
      const { data } = await axiosPrivate.get(
        "/admin/api/misc/cached-statistics/get-status",
        { timeout: 1000 * 3 }
      );
      const { success, jobs } = data;
      if (success) {
        if (jobs?.length) {
          const { state } = jobs[0];
          setJobState(state);
        } else {
          setJobState("completed");
        }
      }
    } catch (err) {
    } finally {
    }
  };

  const invokeUpdateCachedStatistics = async () => {
    try {
      setJobState("active");
      const { data } = await axiosPrivate.post(
        `/admin/api/misc/cached-statistics`,
        {}
      );

      const { success, message } = data;
      if (!success) {
        notification.warning({
          message,
          placement: "bottom",
          duration: 4,
        });
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    }
  };

  return (
    <Space size="small" direction="vertical">
      {contextHolderConfirm}
      <Button
        // icon={
        //   <Progress
        //     steps={5}
        //     showInfo={false}
        //     size={[15, 15]}
        //     percent={
        //       jobProgress?.status === "completed"
        //         ? 100
        //         : Number(
        //             (
        //               (jobProgress?.processed / jobProgress.total) *
        //               100
        //             ).toFixed(1)
        //           )
        //     }
        //   />
        // }
        disabled={!["failed", "completed"].includes(jobState)}
        loading={!["failed", "completed"].includes(jobState)}
        onClick={(e) => {
          invokeUpdateCachedStatistics();
        }}
      >
        <Text>Update Cached Statistics</Text>
      </Button>
    </Space>
  );
};

export default InvokeUpdateCachedStatistics;
