import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../context/hooks/useAuth";
import jwt_decode from "jwt-decode";
import JsonFind from "json-find";

const RequireAccess = ({ requiredAccess }) => {
  const { auth } = useAuth();
  const location = useLocation();

  const decoded = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined;

  const web_app_access = decoded?.UserInfo?.web_app_access || {};
  return JsonFind(web_app_access).checkKey(requiredAccess) ? (
    <Outlet />
  ) : auth?.accessToken ? ( //changed from user to accessToken to persist login after refresh
    <Navigate to="/403" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAccess;
