import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import jwt_decode from "jwt-decode";
import useAuth from "../../context/hooks/useAuth";
import { IconCirclePlus, IconReload } from "@tabler/icons-react";
import zeroFill from "zero-fill";
import isNumber from "isnumber";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import {
  Layout,
  Form,
  Table,
  Button,
  Input,
  Modal,
  Space,
  Select,
  notification,
  Typography,
  Breadcrumb,
  Radio,
  Tooltip,
  Divider,
  InputNumber,
} from "antd";
import breakpoint from "../../Components/breakpoint";

const { Content } = Layout;
const { Search } = Input;
const { Title } = Typography;

const Row = ({ children, ...props }) => {
  //(children);
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform?.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const AdminDevices = () => {
  const { site_id } = useParams();
  const { auth } = useAuth();
  const [site, setSite] = useState({ id: null, sitename: null });
  const [devices, setDevices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  const [seachDevicesStatus, setSearchDevicesStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [frmDevice] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();
  const [modal, contextHolderConfirm] = Modal.useModal();
  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }
  const { roles } = parsedCookies.UserInfo;

  const columns = [
    {
      key: "sort",
      width: 50,
      align: "center",
    },
    // {
    //   dataIndex: "deviceid",
    //   title: "ID",
    //   width: 50,
    //   align: "center",
    // },
    {
      title: "Index",
      dataIndex: "device_index",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return a.device_index > b.device_index;
      },
      width: 100,
      render: (text, record, index) =>
        isNumber(text) ? zeroFill(2, text) : "",
    },
    {
      title: "Device Desc",
      dataIndex: "devicedesc",
      align: "left",
      // defaultSortOrder: "ascend",
      // width: 300,
      // sorter: (a, b) => {
      //   return a.username > b.username;
      // },
    },
    {
      title: "Category",
      dataIndex: "devicetype",
      align: "center",
      width: 150,
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => {
      //   return a.display_name > b.display_name;
      // },
    },
    {
      title: "IP",
      dataIndex: "deviceip",
      align: "center",
      width: 180,
    },
    // {
    //   title: "Roles",
    //   dataIndex: "roles",
    //   align: "center",
    //   width: 200,
    // },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link
              onClick={() => {
                handlerShowDetail(record.deviceid);
              }}
            >
              <EditFilled style={{ fontSize: 15, color: "#4096ff" }} />
            </Link>
            <Link
              onClick={() => {
                handlerDelete(record);
              }}
            >
              <DeleteFilled style={{ fontSize: 15, color: "#cf1322" }} />
            </Link>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        setSearchKey(searchTerm);
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const hasInit = useRef(false);
  useEffect(() => {
    if (!hasInit.current || searchKey !== null) {
      (async () => {
        await getSiteNameById(site_id);
        await loadDevices();
        hasInit.current = true;
      })();
    }
  }, [seachDevicesStatus, searchKey]);

  const handlerSearchUserDisabledChanged = async (status) => {
    setSearchDevicesStatus(status);
    hasInit.current = false;
  };

  const getSiteNameById = async (siteId) => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/admin/sites/get-site-name/${siteId}`
      );

      const { id, sitename } = data;
      setSite({ id, sitename });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadDevices = async () => {
    try {
      setIsLoading(true);
      const query = `/admin/sites/${site_id}/devices?q=${encodeURIComponent(
        searchKey ?? ""
      )}&d=${encodeURIComponent(seachDevicesStatus)}`;
      const { data } = await axiosPrivate.get(query);

      const { devices } = data;
      setDevices(devices);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlerShowForm = () => {
    setFormAction("add");
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    frmDevice?.resetFields();
  };

  const handlerShowDetail = async (deviceid) => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/admin/sites/${site_id}/devices/${deviceid}`
      );
      const {
        devicedesc,
        devicename,
        devicetoken,
        deviceip,
        deviceport,
        devicemac,
        devicetype,
        device_index,
        device_product,
        device_connected_to_server,
        ups_guard_rank,
        ups_guard_shutdown_threshold,
        ups_guard_shutdown,
        ups_guard_wakeup_threshold,
        ups_guard_wakeup,
        router_guard_connected,
        disabled,
      } = data;
      frmDevice?.setFieldsValue({
        devicedesc,
        devicename,
        devicetoken,
        deviceip,
        deviceport,
        devicemac,
        devicetype,
        device_index,
        device_product,
        device_connected_to_server,
        ups_guard_rank,
        ups_guard_shutdown_threshold,
        ups_guard_shutdown,
        ups_guard_wakeup_threshold,
        ups_guard_wakeup,
        router_guard_connected,
        disabled,
      });

      setFormAction("edit");
      setCurrentDeviceId(deviceid);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerDelete = async (device) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete device "${device?.devicedesc}"?`,
      onOk: async () => {
        try {
          setSubmitting(true);
          const { data } = await axiosPrivate.delete(
            `/admin/sites/${site_id}/devices/${device?.deviceid}`
          );
          notification.success({
            message: `Successfully deleted device "${device?.devicedesc}".`,
            placement: "bottom",
            duration: 4,
          });
          const { deviceid, device_index } = data;

          setDevices((state) => {
            const new_state = state.filter(
              (m) => m.deviceid !== device?.deviceid
            );
            new_state.forEach((_device) => {
              if (_device.device_index > device_index) {
                _device.device_index -= 1;
              }
            });
            return [...new_state];
          });
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleFormSubmit = async (data) => {
    try {
      setSubmitting(true);
      if (formAction === "add") {
        const res_add = await axiosPrivate.post(
          `/admin/sites/${site_id}/devices`,
          data
        );
        notification.success({
          message: "Successfully created new device.",
          placement: "bottom",
          duration: 4,
        });
        setDevices((state) => {
          return [...state, { ...res_add.data }];
        });
      } else if (formAction === "edit") {
        const res_update_user = await axiosPrivate.patch(
          `/admin/sites/${site_id}/devices/${currentDeviceId}`,
          data
        );
        notification.success({
          message: `Successfully updated device ${data?.devicedesc}.`,
          placement: "bottom",
          duration: 4,
        });
        setDevices((state) => {
          let found_index = state.findIndex(
            (device) => device.deviceid === currentDeviceId
          );
          state[found_index] = {
            deviceid: currentDeviceId,
            devicename: data?.devicename,
            devicedesc: data?.devicedesc,
            deviceip: data?.deviceip,
            devicetype: data?.devicetype,
            device_index: data?.device_index,
            disabled: data?.disabled,
          };
          return [...state];
        });
      }
      frmDevice?.resetFields();
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      if (over) {
        setDevices((previous) => {
          const activeIndex = previous.findIndex(
            (i) => i.deviceid === active.id
          );
          const activeDevice = previous[activeIndex];
          const overIndex = previous.findIndex((i) => i.deviceid === over?.id);
          const overroduct = previous[overIndex];
          const active_device_index = activeDevice.device_index;
          const over_device_index = overroduct.device_index;
          if (active_device_index > over_device_index) {
            const filteredDevice = previous.filter(
              (device) =>
                device.device_index >= over_device_index &&
                device.device_index < active_device_index
            );
            filteredDevice.forEach((product) => (product.device_index += 1));
            activeDevice.device_index = overroduct.device_index - 1;
          } else if (active_device_index < over_device_index) {
            const filteredDevice = previous.filter(
              (device) =>
                device.device_index > active_device_index &&
                device.device_index <= over_device_index
            );
            filteredDevice.forEach((device) => (device.device_index -= 1));
            activeDevice.device_index = overroduct.device_index + 1;
          }
          return arrayMove(previous, activeIndex, overIndex);
        });
        try {
          setIsLoading(true);
          const { success } = (
            await axiosPrivate.post(
              `/admin/sites/${site_id}/devices/reorder-device`,
              {
                active_device_id: active?.id,
                over_device_id: over?.id,
              }
            )
          ).data;
          if (!success) {
            await loadDevices();
          }
        } catch (err) {
          notification.error({
            message:
              err instanceof AxiosError
                ? err.response.data ?? err?.message
                : err?.message ?? err?.toString(),
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <Content
      className="admin-generic-content"
      style={{ maxWidth: breakpoint.xxl, width: breakpoint.xxl }}
    >
      {contextHolderConfirm}
      <Modal
        title={formAction === "add" ? "Add New Device" : "Edit Device"}
        styles={{
          body: { maxHeight: 550, overflowX: "hidden", overflowY: "auto" },
        }}
        width={600}
        open={isModalOpen}
        onOk={() => {
          frmDevice.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          htmlType: "submit",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: submitting }}
        destroyOnClose
      >
        <Content>
          <Form
            size="middle"
            form={frmDevice}
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            layout="horizontal"
            onFinish={handleFormSubmit}
            disabled={submitting}
            scrollToFirstError={true}
          >
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Basic Settings
            </Divider>
            <Form.Item
              name="devicename"
              style={{ marginBottom: 10 }}
              label="Device Name"
              initialValue={""}
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 200,
                  min: 4,
                },
              ]}
            >
              <Input minLength={4} maxLength={200} showCount />
            </Form.Item>
            <Form.Item
              name="devicedesc"
              style={{ marginBottom: 10 }}
              label="Device Desc."
              initialValue={""}
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 200,
                  min: 4,
                },
              ]}
            >
              <Input minLength={4} maxLength={200} showCount />
            </Form.Item>
            <Form.Item
              name="device_index"
              style={{ marginBottom: 10 }}
              label="Device Index."
              initialValue={0}
              required
              hidden
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "",
                  max: 99,
                  min: 0,
                },
              ]}
            >
              <InputNumber readOnly min={0} max={99} />
            </Form.Item>
            <Form.Item
              name="devicetype"
              style={{ marginBottom: 10 }}
              label="Category"
              initialValue={"Generic"}
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "",
                  max: 50,
                },
              ]}
            >
              <Select
                options={[
                  { label: "Networking", value: "Networking" },
                  { label: "IP Camera", value: "IP Camera" },
                  { label: "NVR", value: "NVR" },
                  { label: "FusionBox", value: "FusionBox" },
                  { label: "Dispenser", value: "Dispenser" },
                  { label: "Computer", value: "Computer" },
                  { label: "Petrovend-System2", value: "Petrovend-System2" },
                  { label: "Petrovend-FSC3000", value: "Petrovend-FSC3000" },
                  { label: "Smart Plug", value: "Smart Plug" },
                  { label: "UPS", value: "UPS" },
                  { label: "Generic", value: "Generic" },
                  { label: "Fuel Tank", value: "Fuel Tank" },
                  { label: "Other", value: "Other" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="device_product"
              style={{ marginBottom: 10 }}
              label="Associated Product."
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                  max: 50,
                  min: 2,
                },
              ]}
            >
              <Input minLength={2} maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="devicetoken"
              style={{ marginBottom: 10 }}
              label="Token"
              initialValue={""}
              rules={[
                {
                  message: "",
                  type: "string",
                  max: 200,
                },
              ]}
            >
              <Input.Password maxLength={200} />
            </Form.Item>
            <Form.Item
              name="disabled"
              label="Disabled"
              style={{ marginBottom: 0 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              Router Guard Settings
            </Divider>
            <Form.Item
              name="router_guard_connected"
              label="Router Monitor"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="deviceip"
              style={{ marginBottom: 10 }}
              label="Device IP"
              initialValue={""}
              rules={[
                {
                  type: "string",
                  message: "",
                  max: 50,
                },
              ]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="deviceport"
              style={{ marginBottom: 10 }}
              label="Device Port"
              required
              initialValue={0}
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "",
                  min: 0,
                  max: 65535,
                },
              ]}
            >
              <InputNumber min={0} max={65535} />
            </Form.Item>
            <Form.Item
              name="devicemac"
              style={{ marginBottom: 10 }}
              label="Display MAC."
              initialValue={""}
              rules={[{ type: "string", max: 30 }]}
            >
              <Input maxLength={30} showCount />
            </Form.Item>
            <Form.Item
              name="device_connected_to_server"
              label="Server Connected"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#7cb305",
              }}
              orientation="center"
            >
              UPS Guard Settings
            </Divider>
            <Form.Item
              name="ups_guard_rank"
              style={{ marginBottom: 10 }}
              label="Rank"
              initialValue={3}
              required
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "",
                  min: 0,
                  max: 10,
                },
              ]}
            >
              <Select
                options={[
                  { label: "0 - Most important", value: 0 },
                  { label: "1 - Second most important", value: 1 },
                  { label: "2 - Third most important", value: 2 },
                  { label: "3 - Mediocre", value: 3 },
                  { label: "4 - Second least important", value: 4 },
                  { label: "5 - Least important", value: 5 },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="ups_guard_shutdown_threshold"
              style={{ marginBottom: 10 }}
              label="Shutdown Threshold"
              initialValue={30}
              required
              rules={[{ required: true, type: "number", max: 99, min: 0 }]}
            >
              <InputNumber min={0} max={99} />
            </Form.Item>
            <Form.Item
              name="ups_guard_wakeup_threshold"
              style={{ marginBottom: 10 }}
              label="Wake-up Threshold"
              initialValue={5}
              required
              rules={[{ required: true, type: "number", max: 99, min: 0 }]}
            >
              <InputNumber min={0} max={99} />
            </Form.Item>
            <Form.Item
              name="ups_guard_shutdown"
              label="Guard Shutdown"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="ups_guard_wakeup"
              label="Guard Wake-up"
              style={{ marginBottom: 10 }}
              initialValue={"No"}
            >
              <Radio.Group>
                <Radio value="No">No</Radio>
                <Radio value="Yes">Yes</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Device List
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Admin Control Panel",
            },
            {
              title: <NavLink to={"/admin/sites"}>Site List</NavLink>,
            },
            {
              title: site?.sitename,
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-header">
          <div className="admin-generic-toolbar">
            <Space size="small" wrap>
              <Search
                allowClear
                disabled={isLoading}
                variant="filled"
                size="large"
                placeholder="Key in to search"
                onChange={handlerSearchChange}
              />
            </Space>
            <Space size="small" wrap>
              {" "}
              <Tooltip title="Reload">
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  size="large"
                  icon={<IconReload />}
                  onClick={async () => {
                    await loadDevices();
                  }}
                />
              </Tooltip>
              <Select
                disabled={isLoading}
                size="large"
                style={{ minWidth: 160 }}
                onChange={handlerSearchUserDisabledChanged}
                defaultValue=""
                options={[
                  { value: "", label: "All Devices" },
                  { value: "No", label: "Active Devices" },
                  { value: "Yes", label: "Disabled Devices" },
                ]}
              />
              <Tooltip title="Add Device">
                <Button
                  icon={<IconCirclePlus />}
                  size="large"
                  type="primary"
                  onClick={() => {
                    handlerShowForm();
                  }}
                  disabled={isLoading}
                ></Button>
              </Tooltip>
            </Space>
          </div>
        </div>
        <div className="admin-generic-card-body">
          <div className="admin-generic-table-wrap">
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={devices.map((i) => i.deviceid)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  style={{ minWidth: 800 }}
                  sticky
                  pagination={{
                    position: ["none", "none"],
                    defaultPageSize: 200,
                  }}
                  columns={columns}
                  rowKey={(device) => device.deviceid}
                  dataSource={devices}
                  loading={isLoading}
                  showSorterTooltip={false}
                  rowClassName={(record) =>
                    record.disabled === "Yes" && "disabled-record"
                  }
                />
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default AdminDevices;
