import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import scrollIntoView from "scroll-into-view";
import qs from "qs";
import useAuth from "../context/hooks/useAuth";
import {
  IconCirclePlus,
  IconReload,
  IconCopy,
  IconHourglassHigh,
  IconKey,
  IconCircleMinus,
  IconCheck,
  IconLoader,
} from "@tabler/icons-react";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import {
  Layout,
  Form,
  Table,
  Button,
  Input,
  Checkbox,
  Modal,
  Space,
  Select,
  notification,
  Typography,
  Breadcrumb,
  Tooltip,
  Pagination,
  Tabs,
  Descriptions,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import { AxiosError } from "axios";
import dayjs from "dayjs";

const { Content } = Layout;
const { Search } = Input;
const { Title, Text } = Typography;

const BulkPlantFleetCards = () => {
  const { auth } = useAuth();
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [hasInitialised, setHasInitialised] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sites, setSites] = useState([]);
  const [currentCardId, setCurrentCardId] = useState(null);
  const [currentCardNumber, setCurrentCardNumber] = useState(null);
  const [moreSettings, setMoreSettings] = useState(false);
  const [seachCardsStatus, setSearchCardsStatus] = useState(false);
  const [searchCardsSyncStatus, setSearchCardsSyncStatus] = useState("");
  const [cardDisabled, setCardDisabled] = useState(false);
  const [cardNumberPrefix, setCardNumberPrefix] = useState("19470000000");
  const [cardSageInfo, setCardSageInfo] = useState({});
  const [searchSiteId, setSearchSiteId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const [frmCard] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();
  const [modal, contextHolderConfirm] = Modal.useModal();
  const [api, contextHolder] = notification.useNotification();

  const defaultPageSize = 25;
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["none", "none"],
      current: 1,
      pageSize: defaultPageSize,
    },
  });

  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }

  const columns = [
    {
      title: "Card Number",
      dataIndex: "card_number",
      align: "center",
      defaultSortOrder: "ascend",
      width: 200,
      sorter: (a, b) => {
        return a.card_number > b.card_number;
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      align: "left",
      minWidth: 120,
      responsive: ["lg"],
    },
    {
      title: "Comment",
      dataIndex: "comment",
      align: "left",
      minWidth: 120,
      responsive: ["xl"],
    },
    {
      title: "Last Updated",
      dataIndex: "datetime_updated",
      align: "center",
      width: 160,
      render: (text, record, index) => {
        return dayjs(text).format("MM-DD HH:mm");
      },
    },
    // {
    //   title: "Last Login",
    //   dataIndex: "last_login",
    //   align: "center",
    //   width: 130,
    //   responsive: ["xl"],
    //   render: (text, record, index) => {
    //     return new Date(text).toString();
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 80,
      render: (text, record, index) => {
        return record?.number_of_pending_task ? (
          <IconHourglassHigh stroke={1.5} size={18} color="orange" />
        ) : (
          <IconCheck stroke={1.5} size={18} color="darkgreen" />
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 80,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link
              onClick={() => {
                handlerShowDetail(record.card_id);
              }}
            >
              <EditFilled style={{ fontSize: 15, color: "#4096ff" }} />
            </Link>
            <Link
              hidden
              onClick={() => {
                handlerDelete(record);
              }}
            >
              <DeleteFilled style={{ fontSize: 15, color: "#cf1322" }} />
            </Link>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        setSearchKey(searchTerm);
        setTableParams({
          pagination: { ...tableParams.pagination, current: 1 },
        });
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const hasInit = useRef(false);

  useEffect(() => {
    if (!hasInit.current || searchKey !== null || hasInitialised) {
      (async () => {
        await loadSites();
        await loadFleetCards();
        hasInit.current = true;
      })();
    }
  }, [
    searchCardsSyncStatus,
    seachCardsStatus,
    searchSiteId,
    searchKey,
    tableParams.pagination.current,
  ]);

  const handlerSearchSitesChanged = async (site_id) => {
    setSearchSiteId(site_id);
    setTableParams({ pagination: { ...tableParams.pagination, current: 1 } });
    hasInit.current = false;
  };

  const handlerSearchCardsDisabledChanged = async (status) => {
    setSearchCardsStatus(status);
    setTableParams({ pagination: { ...tableParams.pagination, current: 1 } });
  };

  const handlerSearchCardsStatusChanged = async (status) => {
    setSearchCardsSyncStatus(status);
    setTableParams({ pagination: { ...tableParams.pagination, current: 1 } });
    hasInit.current = false;
  };

  const loadSites = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/regular/api/get-sites`);

      const { sites } = data ?? [];
      /**
       * fleetcard_sites.status, fleetcard_sites.pending_removal, fleetcard_sites.pending_update_pin
       */

      setSites(sites);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const loadFleetCards = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/regular/bulkplant-fleetcards?q=${encodeURIComponent(
          searchKey ?? ""
        )}&d=${encodeURIComponent(seachCardsStatus)}&t=${encodeURIComponent(
          searchCardsSyncStatus
        )}&s=${searchSiteId}&${qs.stringify(tableParams)}`
      );

      const { cards, total_count } = data;
      setCards(cards);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total_count,
        },
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
      setHasInitialised(true);
      scrollToTopOfTable();
    }
  };

  const handlerSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlerShowForm = () => {
    setFormAction("add");
    setIsModalOpen(true);
    setCardDisabled(false);
  };

  const handlerCloneCard = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/regular/fleetcards/${selectedRow?.card_id}`
      );

      const { card_id, site_ids, disabled, vehicle, comment, memo } =
        data?.card;

      frmCard?.setFieldsValue({
        card_id,
        site_ids,
        disabled,
        vehicle,
        comment,
        memo,
      });

      setFormAction("add");
      setCurrentCardId(selectedRow?.card_id);
      setCurrentCardNumber(selectedRow?.card_number);
      setIsModalOpen(true);
      setCardDisabled(disabled);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    frmCard?.resetFields();
  };

  const handlerShowDetail = async (card_id) => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/regular/fleetcards/${card_id}`);
      const {
        card_number,
        card_pin,
        site_ids,
        disabled,
        vehicle,
        comment,
        memo,
        datetime_created,
        datetime_updated,
      } = data?.card;
      frmCard?.setFieldsValue({
        card_number:
          card_number?.length >= 5
            ? card_number?.substring(card_number?.length - 5)
            : card_number,
        card_pin: card_pin,
        site_ids,
        disabled,
        vehicle,
        comment,
        memo,
        datetime_created,
        datetime_updated,
      });
      setCardDisabled(disabled);

      setFormAction("edit");
      setCurrentCardId(card_id);
      setCurrentCardNumber(card_number);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerDelete = async (user) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete this user "${user?.username}"?`,
      onOk: async () => {
        try {
          setSubmitting(true);
          await axiosPrivate.delete(`/admin/user/${user?.id}`);
          notification.success({
            message: `Successfully deleted the user "${user?.username}".`,
            placement: "bottom",
            duration: 4,
          });
          setCards((state) => {
            const new_state = state.filter((m) => m.id !== user?.id);
            return [...new_state];
          });
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleFormSubmit = async (data) => {
    try {
      const card_number_with_prefix = `${cardNumberPrefix}${data?.card_number}`;
      setSubmitting(true);
      if (formAction === "add") {
        const { data: dataReturnFromServer } = await axiosPrivate.post(
          `/regular/fleetcards/`,
          {
            ...data,
            card_number: card_number_with_prefix,
            card_type: "bulkplant",
          }
        );
        notification.success({
          message: `Successfully created new fleet card "${card_number_with_prefix}".`,
          placement: "bottom",
          duration: 4,
        });
        const { number_of_pending_task } = dataReturnFromServer;
        setCards((state) => {
          return [...state, { ...dataReturnFromServer }];
        });
      } else if (formAction === "edit") {
        const { data: dataUpdatedResult } = await axiosPrivate.patch(
          `/regular/fleetcards/${currentCardId}`,
          {
            ...data,
            card_number: card_number_with_prefix,
            card_type: "bulkplant",
          }
        );
        const { datetime_updated, number_of_pending_task, disabled } =
          dataUpdatedResult;
        notification.success({
          message: `Successfully updated fleet card ${card_number_with_prefix}.`,
          placement: "bottom",
          duration: 4,
        });
        setCards((state) => {
          let found_index = state.findIndex(
            (card) => card.card_id === currentCardId
          );
          state[found_index] = {
            ...data,
            disabled,
            card_number: card_number_with_prefix,
            card_id: currentCardId,
            number_of_pending_task,
            datetime_updated: datetime_updated,
          };
          return [...state];
        });
      }
      frmCard?.resetFields();
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? Array.isArray(err.response?.data?.errors)
              ? err.response?.data?.errors
                  ?.map((e) => `[${e?.path}]: ${e?.msg}`)
                  .join("\r\n")
              : err.response?.data
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: page,
        },
      };
    });
    if (pageSize !== tableParams.pagination?.pageSize) {
      setCards([]);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ ...tableParams, sorter, ...filters });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCards([]);
    }
    // refTable.current.scrollTo({ top: 1 });
  };

  const scrollToTopOfTable = () => {
    scrollIntoView(document.querySelector(".scroll-first-row"), {
      align: {
        // top: 0,
        topOffset: 0,
      },
    });
  };

  return (
    <Content
      className="admin-generic-content"
      // style={{ minWidth: 1, maxWidth: breakpoint.xxl, width: breakpoint.xxl }}
    >
      {contextHolderConfirm}
      {contextHolder}
      <Modal
        title={formAction === "add" ? "Create Fleet Card" : "Edit Fleet Card"}
        style={{ minWidth: 430 }}
        styles={{
          body: {
            minHeight: 550,
            maxHeight: 550,
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        width={650}
        open={isModalOpen}
        onOk={() => {
          frmCard.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: submitting || isLoading,
          loading: submitting,
          htmlType: "submit",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{
          disabled: submitting || isLoading || isLoadingSites,
        }}
        destroyOnClose
        afterOpenChange={async (open) => {
          if (open) {
            try {
              setIsLoadingSites(true);
              const { data } =
                formAction === "add"
                  ? await axiosPrivate.get(`/regular/api/get-sites`)
                  : await axiosPrivate.get(
                      `/regular/api/get-sites?with_pending_status=true&card_id=${currentCardId}`
                    );

              const { sites } = data ?? [];
              /**
               * fleetcard_sites.status, fleetcard_sites.pending_removal, fleetcard_sites.pending_update_pin
               */

              setSites(sites);
            } catch (err) {
            } finally {
              setIsLoadingSites(false);
            }
          }
        }}
        afterClose={() => {
          setMoreSettings(false);
          // setSites([]);
        }}
      >
        <Content>
          <Tabs
            defaultActiveKey="tabFleetcard"
            items={[
              {
                key: "tabFleetcard",
                label: "Card Info",
                children: (
                  <Form
                    size="middle"
                    form={frmCard}
                    labelCol={{ span: 6, offset: 0 }}
                    labelAlign="left"
                    labelWrap
                    layout="horizontal"
                    onFinish={handleFormSubmit}
                    disabled={submitting || isLoading || isLoadingSites}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="card_number"
                      style={{ marginBottom: 10 }}
                      label="Card Number"
                      required
                      rules={[
                        {
                          required: true,
                          type: "string",
                          message: "",
                          min: 5,
                          max: 5,
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input
                        addonBefore={
                          <Select
                            disabled={formAction === "edit"}
                            defaultValue={"19470000000"}
                            options={[
                              { label: "19470000000", value: "19470000000" },
                            ]}
                            onChange={(value) => {
                              setCardNumberPrefix(value);
                            }}
                          />
                        }
                        disabled={formAction === "edit"}
                        readOnly={formAction === "edit"}
                        minLength={5}
                        maxLength={5}
                        style={{ width: "100%" }}
                        step={1}
                      />
                    </Form.Item>
                    <Form.Item
                      name="card_pin"
                      style={{ marginBottom: 10 }}
                      label="Card Pin"
                      initialValue={""}
                      required
                      rules={[
                        {
                          required: true,
                          type: "string",
                          message: "",
                          max: 8,
                          min: 1,
                        },
                      ]}
                    >
                      <Input
                        minLength={1}
                        maxLength={8}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      label="Sites"
                      name={"site_ids"}
                      initialValue={[]}
                      rules={[{ type: "array" }]}
                    >
                      {isLoadingSites && <Text>Loading...</Text>}
                      <Checkbox.Group
                        disabled={cardDisabled}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                        }}
                        options={[
                          ...sites
                            ?.filter(
                              (site) =>
                                ["bulkplant", "both", "retail"].includes(
                                  site?.site_type
                                ) && site?.list_by_fleetcard_manager
                            )
                            .map((site) => ({
                              disabled: ["retail"].includes(site?.site_type)
                                ? true
                                : false,
                              value: site?.id,
                              label:
                                site?.status === "pending" ||
                                site?.pending_removal ||
                                site?.pending_update_pin ||
                                site?.pending_creation ? (
                                  <>
                                    {site?.status && (
                                      <IconHourglassHigh
                                        color="orange"
                                        stroke={1.5}
                                        size={14}
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    )}
                                    {site?.pending_creation && (
                                      <IconCirclePlus
                                        color="green"
                                        stroke={1.5}
                                        size={14}
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    )}
                                    {site?.pending_update_pin && (
                                      <IconKey
                                        color="orange"
                                        stroke={1.5}
                                        size={14}
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    )}
                                    {site?.pending_removal && (
                                      <IconCircleMinus
                                        color="orange"
                                        stroke={1.5}
                                        size={14}
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    )}{" "}
                                    <Text
                                      style={{
                                        color: cardDisabled ? "lightgrey" : "",
                                      }}
                                    >
                                      {site?.sitename}
                                    </Text>
                                  </>
                                ) : (
                                  site?.sitename
                                ),
                            })),
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="vehicle"
                      style={{ marginBottom: 10 }}
                      label="Vehicle"
                      initialValue={""}
                      rules={[
                        {
                          type: "string",
                          message: "",
                          max: 20,
                          whitespace: false,
                        },
                      ]}
                      tooltip={`Max 20 characters and any of ' " \` / \\ ? # ! | ( ) & ^ % $ isn't allowed.`}
                    >
                      <Input maxLength={20} showCount />
                    </Form.Item>
                    <Form.Item
                      name="comment"
                      style={{ marginBottom: 10 }}
                      label="Comment"
                      initialValue={""}
                      rules={[
                        {
                          type: "string",
                          message: "",
                          max: 100,
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input maxLength={100} showCount />
                    </Form.Item>
                    <Form.Item
                      name="memo"
                      style={{ marginBottom: 10 }}
                      label="Memo"
                      initialValue={""}
                      rules={[
                        {
                          type: "string",
                          message: "",
                          max: 255,
                          whitespace: false,
                        },
                      ]}
                    >
                      <TextArea
                        maxLength={255}
                        style={{ height: 100 }}
                        showCount
                      />
                    </Form.Item>
                    <Form.Item
                      name="disabled"
                      label="Disabled"
                      required
                      style={{ marginBottom: 10 }}
                      initialValue={false}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setCardDisabled(checked);
                        }}
                      ></Checkbox>
                    </Form.Item>
                    <Form.Item
                      hidden
                      label="More Settings"
                      name={"more_settings"}
                      style={{ marginBottom: 10 }}
                      initialValue={false}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) =>
                          setMoreSettings(e?.target?.checked ?? false)
                        }
                      ></Checkbox>
                    </Form.Item>
                    <Form.Item
                      hidden={!moreSettings}
                      label="Pump Restriction"
                      name={"pump_rst"}
                      style={{ marginBottom: 10 }}
                      initialValue={"0"}
                    >
                      <Select
                        options={[
                          { value: "0", label: "No Restrictions" },
                          { value: "5", label: "All Cardlock" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      hidden={!moreSettings}
                      label="Qty Restriction"
                      name={"quan_rst"}
                      style={{ marginBottom: 10 }}
                      initialValue={"0"}
                    >
                      <Select
                        options={[{ value: "0", label: "No Restrictions" }]}
                      />
                    </Form.Item>
                    <Form.Item
                      hidden={!moreSettings}
                      label="Unit #"
                      name={"misc_entry"}
                      style={{ marginBottom: 10 }}
                      initialValue={false}
                      valuePropName="checked"
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Form>
                ),
              },
              formAction === "edit" && {
                key: "tabSage",
                label: "Sage Info",
                children: isLoading ? (
                  <Text>Loading...</Text>
                ) : (
                  <Descriptions column={1} bordered size="middle">
                    <Descriptions.Item label="Account Name">
                      {cardSageInfo?.accountname ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Account No.">
                      {cardSageInfo?.accountno ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact">
                      {cardSageInfo?.contact ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address">
                      {cardSageInfo?.address ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {cardSageInfo?.city ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Post Code">
                      {cardSageInfo?.zipcode ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date Updated">
                      {cardSageInfo?.dateupdated ?? ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date Created">
                      {cardSageInfo?.datecreated ?? ""}
                    </Descriptions.Item>
                  </Descriptions>
                ),
              },
              formAction === "edit" && {
                key: "tabUpdateHistory",
                label: "Update History",
                children: isLoading ? (
                  <Text>Loading...</Text>
                ) : (
                  <Table
                    bordered
                    size="middle"
                    columns={[
                      { title: "Timestamp", align: "center", width: 140 },
                      { title: "User", align: "left" },
                      { title: "Action", align: "center", width: 80 },
                    ]}
                  />
                ),
              },
            ]}
            onChange={async (activeKey) => {
              if (activeKey === "tabSage") {
                try {
                  setIsLoading(true);
                  const { data } = await axiosPrivate.get(
                    `/regular/fleetcards/${currentCardNumber}/sage-info`
                  );
                  const { success, account } = data;
                  if (success) {
                    setCardSageInfo(account);
                  } else {
                    setCardSageInfo({});
                  }
                } catch (err) {
                } finally {
                  setIsLoading(false);
                }
              }
            }}
          />
        </Content>
      </Modal>
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Fleet Cards - Bulk Plants
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Fleet Cards",
            },

            {
              title: "Bulk Plants",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-header">
          <div className="admin-generic-toolbar">
            <Space size="small" wrap>
              <Search
                allowClear
                disabled={isLoading}
                variant="filled"
                size="middle"
                placeholder="Key in to search"
                onChange={handlerSearchChange}
              />
            </Space>
            <Space size="small" wrap>
              <Tooltip title="Reload">
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  size="middle"
                  icon={<IconReload />}
                  onClick={async () => {
                    await loadFleetCards();
                  }}
                />
              </Tooltip>
              <Select
                disabled={isLoading}
                size="middle"
                style={{ minWidth: 180 }}
                onChange={handlerSearchSitesChanged}
                defaultValue={""}
                options={[
                  { value: "", label: "All Plants" },
                  ...sites
                    ?.filter(
                      (site) =>
                        ["bulkplant", "both"].includes(site?.site_type) &&
                        site?.list_by_fleetcard_manager
                    )
                    ?.map((site) => ({
                      label: site?.sitename,
                      value: site?.id,
                    })),
                ]}
              />
              <Select
                disabled={isLoading}
                size="middle"
                style={{ minWidth: 180 }}
                onChange={handlerSearchCardsStatusChanged}
                defaultValue={""}
                options={[
                  { value: "", label: "Sync Status Not Set" },
                  { value: "pending", label: "Pending" },
                ]}
              />
              <Select
                disabled={isLoading}
                size="middle"
                style={{ minWidth: 180 }}
                onChange={handlerSearchCardsDisabledChanged}
                defaultValue={false}
                options={[
                  { value: false, label: "Card Status Not Set" },
                  { value: true, label: "Disabled Cards" },
                ]}
              />
              <Tooltip title="Create Card">
                <Button
                  icon={<IconCirclePlus />}
                  size="middle"
                  type="primary"
                  onClick={() => {
                    handlerShowForm();
                  }}
                  disabled={isLoading}
                ></Button>
              </Tooltip>
              <Tooltip title="Clone Card">
                <Button
                  icon={<IconCopy />}
                  size="middle"
                  type="default"
                  onClick={() => {
                    handlerCloneCard();
                  }}
                  disabled={isLoading || selectedRow === null}
                ></Button>
              </Tooltip>
            </Space>
          </div>
        </div>
        <div className="admin-generic-card-body">
          <div className="admin-generic-table-wrap">
            <Table
              bordered
              sticky
              pagination={{
                position: ["none", "none"],
                defaultPageSize: defaultPageSize,
              }}
              columns={columns}
              rowKey={(card) => card.card_id}
              dataSource={cards}
              loading={isLoading}
              showSorterTooltip={false}
              rowClassName={(record, index) => {
                const classes = [];
                if (record.disabled) {
                  classes.push("disabled-record");
                }
                if (index <= 1) {
                  classes.push("scroll-first-row");
                }
                return classes.join(" ");
              }}
              scroll={{ scrollToFirstRowOnChange: true }}
              rowSelection={{
                hideSelectAll: true,
                onChange: (selectedKeys, selectedRows) => {
                  setSelectedRow(selectedRows?.length ? selectedRows[0] : null);
                },
                type: "radio",
              }}
              onChange={handleTableChange}
            />
          </div>
          <Content className="pagination">
            <Button
              size="middle"
              disabled={isLoading}
              loading={isLoading}
              icon={<IconReload />}
              onClick={async () => {
                await loadFleetCards();
              }}
            />
            <Pagination
              size="middle"
              defaultCurrent={1}
              disabled={isLoading}
              showSizeChanger={false}
              current={tableParams.pagination.current}
              pageSize={defaultPageSize}
              defaultPageSize={tableParams.pagination.pageSize}
              total={tableParams.pagination?.total}
              onChange={handlePageChange}
              showQuickJumper
            />
          </Content>
        </div>
      </div>
    </Content>
  );
};

export default BulkPlantFleetCards;
