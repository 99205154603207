import axios from "axios";
const BASE_URL = "https://fleetcard-api.north60petro.com";
//const BASE_URL = "http://localhost:3050";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 1000 * 60 * 3,
});

export const baseUrl = BASE_URL;
