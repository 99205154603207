import { useLocation } from "react-router-dom";

const useSelectedMenuKeys = ({ mainMenus = [] }) => {
  const location = useLocation();
  const current_pathname = location.pathname;

  const matchingItemKeys = [];

  mainMenus.forEach((item) => {
    if (
      item?.key?.includes(current_pathname) ||
      (item?.reg && item.reg.test(current_pathname))
    ) {
      matchingItemKeys.push(item?.key);
    } else {
      if (Array.isArray(item?.children)) {
        item?.children?.map((child) => {
          if (
            child?.key === current_pathname ||
            (child?.reg && child.reg.test(current_pathname))
          ) {
            matchingItemKeys.push(child?.key);
          }
        });
      }
    }
  });
  return matchingItemKeys;
};

export default useSelectedMenuKeys;
