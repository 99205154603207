import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Layout,
  Typography,
} from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import { useWindowSize } from "@uidotdev/usehooks";
import useAuth from "../context/hooks/useAuth";
import axios from "../api/axios";
import styles from "./Login.module.css";
import BreakPoint from "../Components/breakpoint";

const LOGIN_URL = "/auth";
const { Content } = Layout;
const { Text } = Typography;

const Login = () => {
  const { width: screenWidth } = useWindowSize();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordResetModalStatus, setPasswordResetModalStatus] =
    useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [frmLogin] = Form.useForm();
  const [frmResetPassword] = Form.useForm();
  const from = location.state?.from?.pathname || "/";

  const onFinish = async (values) => {
    const { remember_username, username } = values;
    try {
      setIsSubmitting(true);
      const res_auth = await axios.post(LOGIN_URL, values, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (remember_username) {
        localStorage.setItem("__fleetcard_manager_username", username);
      } else {
        localStorage.removeItem("__fleetcard_manager_username");
      }
      const accessToken = res_auth?.data?.accessToken;
      setAuth({ user: values.username, accessToken });
      navigate(from, { replace: true });
    } catch (err) {
      if (err instanceof AxiosError) {
        notification.error({
          message: `Server responded error code: ${
            err?.response?.data
              ? err?.response?.data?.message
              : "Service not reachable."
          }.`,
          duration: 5,
        });
      } else {
        notification.error({
          message: "Incorrect login details.",
          duration: 5,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const frmPasswordResetSubmitCallback = async (values) => {
    try {
      setIsResettingPassword(true);
      const resResetPassword = await axios.post(
        "/register/password-reset",
        values,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      notification.success({
        message: "A password reset link has been send to your email, thanks.",
        duration: 8,
      });
      setPasswordResetModalStatus(false);
    } catch (err) {
      notification.error({
        message: "Something went wrong, please try again later.",
        duration: 5,
      });
    } finally {
      setIsResettingPassword(false);
    }
  };

  notification.config({ maxCount: 3 });

  return (
    <div className={styles.login_canvas}>
      <Modal
        title={`Reset Password`}
        open={passwordResetModalStatus}
        onOk={() => {
          frmResetPassword.submit();
        }}
        okText="Submit"
        okButtonProps={{
          disabled: isResettingPassword,
          loading: isResettingPassword,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setPasswordResetModalStatus(false);
        }}
        afterClose={() => {
          frmResetPassword.resetFields();
        }}
        cancelButtonProps={{ disabled: isResettingPassword }}
        destroyOnClose
      >
        <Content>
          <Space direction="vertical">
            <Text style={{ color: "#cf1322" }}>
              Please enter your email and if it matches with our records, a
              password reset link will be emailed to you.
            </Text>
          </Space>
          <Form
            style={{ marginTop: 10 }}
            form={frmResetPassword}
            labelCol={{ span: 9, offset: 0 }}
            labelAlign="left"
            onFinish={frmPasswordResetSubmitCallback}
            disabled={isResettingPassword}
          >
            <Form.Item
              name="email_address"
              style={{ marginBottom: 10 }}
              label="Registered Email"
              rules={[
                {
                  required: true,
                  min: 5,
                  max: 100,
                  message: "",
                },
              ]}
            >
              <Input minLength={5} maxLength={100} required showCount />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      {screenWidth > BreakPoint.xl && (
        <div className={styles.login_brand}>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
            }}
          >
            <div>
              {/* <img
              className={styles.login_brand_logo}
              src={`${baseUrl}/resources/Final-Logo-With-25th-removebg-preview.png`}
            /> */}
            </div>
            <div className={styles.login_brand_title}>North60 Petro</div>
            <div className={styles.login_brand_sub_title}>
              Fine Fuels. Super Service. Quality Lubricants.
            </div>
          </div>
        </div>
      )}
      <div className={styles.login_panel}>
        <div
          className={
            screenWidth > BreakPoint.xl
              ? styles.login_content
              : styles.login_content_mobile
          }
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              fontWeight: 500,
              lineHeight: "3rem",
            }}
          >
            Fleetcard Manager
          </div>
          <div style={{ textAlign: "center", lineHeight: "1.7rem" }}>
            Sign in to your account
          </div>
          <Form
            form={frmLogin}
            size="large"
            name="user_login"
            className={styles.login_form}
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            onFinish={onFinish}
            style={{ width: "100%" }}
            disabled={isSubmitting}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Please enter your Username!",
                },
              ]}
              initialValue={localStorage.getItem(
                "__fleetcard_manager_username"
              )}
            >
              <Input width="100%" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              help="Case sensitive"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Please enter your Password!",
                },
              ]}
            >
              <Input prefix={<LockOutlined />} type="password" />
            </Form.Item>
            <Form.Item>
              <Form.Item
                style={{ marginBottom: 5 }}
                name="remember_username"
                valuePropName="checked"
                initialValue={
                  localStorage.getItem("__fleetcard_manager_username")
                    ? true
                    : false
                }
              >
                <Checkbox>Remember Username</Checkbox>
              </Form.Item>
              <Link
                hidden
                className={styles.login_form_forgot}
                onClick={() => {
                  setPasswordResetModalStatus(true);
                }}
              >
                Forgot password
              </Link>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.login_form_button}
                loading={isSubmitting}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        className={
          screenWidth > BreakPoint.xl
            ? styles.login_footer
            : styles.login_footer_mobile
        }
      >
        Unlock your project performance
      </div>
    </div>
  );
};

export default Login;
