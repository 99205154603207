import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import { Buffer } from "buffer";
import isNumber from "isnumber";
import numberFormatter from "number-formatter";
import { Parser } from "@json2csv/plainjs";
import { useWindowWidth } from "@react-hook/window-size";
import {
  IconLoader,
  IconAlertTriangle,
  IconDownload,
  IconFileTypeCsv,
  IconBan,
} from "@tabler/icons-react";
import dayjs from "dayjs";

import {
  Layout,
  Form,
  Button,
  Dropdown,
  Typography,
  notification,
  Breadcrumb,
  Table,
  DatePicker,
  Space,
} from "antd";

import breakpoint from "../Components/breakpoint";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const ChilkootRetailInventory = () => {
  const [frmDeposits] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [csvObject, setCSVObject] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const onlyWidth = useWindowWidth();

  const initCheck = useRef(true);

  useEffect(() => {
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        await loadInventoryReport();
      })();
    }
  }, []);

  const loadInventoryReport = async () => {
    setIsLoading(true);
    setInventory([]);
    setCSVObject({});
    try {
      const { data } = await axiosPrivate.post(
        `/regular/downloads/chilkoot-retail-inventory`
      );
      const { success, results } = data;

      if (success) {
        const _inventory = results?.find(
          (record) => record?.sitename === "Chilkoot"
        )?.data;
        setInventory(_inventory);
        setCSVObject(getDownloadObject(_inventory));
      } else {
        notification.error({
          message: "Failed loading inventory report.",
          placement: "bottom",
          duration: 4,
        });
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
      setInventory([]);
      setCSVObject({});
    } finally {
      setIsLoading(false);
    }
  };

  const getDownloadObject = (inventory = []) => {
    try {
      const parser = new Parser({
        delimiter: ",",
        includeEmptyRows: false,
        header: false,
      });
      const csvContent = parser.parse(inventory);
      let bufferObj = Buffer.from(csvContent, "utf8");
      let base64String = `data:text/csv;base64,${bufferObj.toString("base64")}`;
      let filename = `retail-inventory-chilkoot`;

      return { success: true, message: "", base64String, filename };
    } catch (err) {
      return { success: false, message: err?.toString() };
    }
  };

  return (
    <Content
      className="admin-generic-content"
      style={{
        maxWidth: breakpoint.lg,
        width: breakpoint.lg,
        justifySelf: "start",
        alignSelf: "flex-start",
      }}
    >
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Chilkoot Inventory (No Fuel)
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Downloads",
            },

            {
              title: "Chilkoot Inventory",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-body">
          <Form
            form={frmDeposits}
            style={{ margin: "20px 20px 20px 0" }}
            // labelCol={{ span: 7, offset: 0 }}
            wrapperCol={{ offset: 0 }}
            // labelAlign="left"
            layout={onlyWidth >= breakpoint.md ? "inline" : "vertical"}
            onFinish={loadInventoryReport}
            disabled={submitting || isLoading}
            size="middle"
          >
            <Form.Item>
              <Space size={"small"}>
                <Button htmlType="submit" type="default" loading={submitting}>
                  Refresh
                </Button>
                <Button
                  disabled={!csvObject?.base64String}
                  href={csvObject?.base64String}
                  download={csvObject?.filename}
                  type="default"
                  icon={
                    <IconFileTypeCsv
                      stroke={1.2}
                      size={20}
                      style={{ verticalAlign: "middle", marginBottom: 3 }}
                    />
                  }
                >
                  Export
                </Button>
              </Space>
            </Form.Item>
          </Form>
          <Table
            size="middle"
            bordered
            loading={isLoading || submitting}
            columns={[
              {
                title: "Stock Code",
                dataIndex: "STOCK_CODE",
                align: "center",
                defaultSortOrder: "ascend",
                width: 150,
                sorter: (a, b) => {
                  return a.STOCK_CODE > b.STOCK_CODE;
                },
                // render: (value, record, index) => {
                //   return value === "All Sites" ? (
                //     <Text style={{ fontWeight: "bold" }}>{value}</Text>
                //   ) : (
                //     value
                //   );
                // },
              },
              {
                title: "Department",
                dataIndex: "DEPT_NAME",
                align: "center",
                width: 200,
                responsive: ["lg"],
              },
              {
                title: "Description",
                dataIndex: "DESCRIPT",
                align: "left",
                minWidth: 150,
                responsive: ["md"],
              },
              {
                title: "Cost",
                dataIndex: "COST",
                align: "right",
                width: 100,
                responsive: ["lg"],
                render: (value, record, index) => {
                  return isNumber(value)
                    ? numberFormatter("$###,###,###,##0.00", value)
                    : "-";
                },
              },
              {
                title: "Quantity",
                dataIndex: "INSTOCK",
                align: "right",
                width: 100,
                render: (value, record, index) => {
                  return isNumber(value)
                    ? numberFormatter("###,###,###,##0.0", value)
                    : "-";
                },
              },
            ]}
            dataSource={inventory}
            rowKey={(inv) => inv?.STOCK_CODE}
            pagination={{
              position: ["none", "bottomRight"],
              showSizeChanger: false,
              showQuickJumper: true,
              defaultPageSize: 25,
            }}
          />
        </div>
      </div>
    </Content>
  );
};

export default ChilkootRetailInventory;
