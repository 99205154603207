import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import { Buffer } from "buffer";
import { useWindowWidth } from "@react-hook/window-size";
import {
  IconLoader,
  IconAlertTriangle,
  IconDownload,
  IconFileTypeCsv,
  IconBan,
} from "@tabler/icons-react";
import dayjs from "dayjs";

import {
  Layout,
  Form,
  Button,
  Dropdown,
  Typography,
  notification,
  Breadcrumb,
  Table,
  DatePicker,
  Tooltip,
} from "antd";

import breakpoint from "../Components/breakpoint";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const Deposits = () => {
  const [frmDeposits] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const onlyWidth = useWindowWidth();

  const initCheck = useRef(true);

  useEffect(() => {
    const loadSites = async () => {
      setIsLoading(true);
      try {
        const { data } = await axiosPrivate.get(`/regular/api/get-sites`);
        const { success, sites } = data;

        if (success) {
          setSites([
            ...sites
              ?.filter((site) => site?.downloaddeposit === "Yes")
              ?.map((site) => ({
                ...site,
                deposits: null,
                operation: "",
              })),
          ]);
        } else {
          notification.error({
            message: "Failed loading site list.",
            placement: "bottom",
            duration: 4,
          });
        }
      } catch (err) {
        notification.error({
          message:
            err instanceof AxiosError
              ? err?.response?.data ?? err?.message
              : err?.message ?? err?.toString(),
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        await loadSites();
      })();
    }
  }, []);

  const callbackSearchDeposit = async (value) => {
    try {
      setSubmitting(true);
      setSites(
        sites?.map((site) => ({
          ...site,
          deposits: null,
          operation: <IconLoader size={20} />,
        }))
      );
      const { date_range } = value;
      const [date1, date2] = date_range;
      const { data } = await axiosPrivate.post(
        "/regular/downloads/search-deposit",
        {
          date1: dayjs(date1).format("YYYY-MM-DD"),
          date2: dayjs(date2).format("YYYY-MM-DD"),
        }
      );

      const { success, results } = data;
      // console.log(results);
      if (success && results?.length) {
        setSites([
          ...sites
            ?.filter((site) => site?.sitename !== "All Sites")
            ?.map((site) => ({
              ...site,
              deposits: results?.find(
                (result) => result?.sitename === site?.sitename
              ),
            })),
          ,
          {
            sitename: "All Sites",
            deposits: {
              success: true,
              sitename: "CombinedDeposit",
              depositDateBeginning: results?.filter((result) => result?.success)
                ?.length
                ? results?.filter((result) => result?.success)[0]
                    ?.depositDateBeginning
                : "error",
              depositDateEnding: results?.filter((result) => result?.success)
                ?.length
                ? results?.filter((result) => result?.success)[0]
                    ?.depositDateEnding
                : "error",
              semiformatted: results
                ?.map((result) => result?.semiformatted)
                ?.flat(1),
            },
          },
        ]);
      } else {
        setSites(
          sites?.map((site) => ({
            ...site,
            deposits: null,
          }))
        );
      }
    } catch (err) {
      console.log(err?.message);
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
      setSites(
        sites?.map((site) => ({
          ...site,
          deposits: null,
          operation: <IconAlertTriangle size={20} color="red" />,
        }))
      );
    } finally {
      setSubmitting(false);
    }
  };

  const getDownloadObject = (siteDeposits) => {
    try {
      const {
        semiformatted,
        sitename,
        depositDateBeginning,
        depositDateEnding,
        message,
      } = siteDeposits;
      if (Array.isArray(semiformatted) && semiformatted?.length) {
        let csvContent = "";
        semiformatted?.forEach(
          (row, index) =>
            (csvContent += row?.replaceAll("[INDEX]", index + 1) + "\n")
        );
        let bufferObj = Buffer.from(csvContent, "utf8");
        let base64String = `data:text/csv;base64,${bufferObj.toString(
          "base64"
        )}`;
        let filename = `deposit-${sitename.replace(
          /\s/g,
          ""
        )}-${depositDateBeginning}-${depositDateEnding}`;

        return { success: true, message: "", base64String, filename };
      } else {
        return {
          success: false,
          message: Array.isArray(semiformatted)
            ? "No Records Found"
            : ["ENOTONLINE"].includes(message)
            ? "Connector Error"
            : message,
        };
      }
    } catch (err) {
      console.error(err);
      return { success: false, message: err?.toString() };
    }
  };

  return (
    <Content
      className="admin-generic-content"
      style={{
        maxWidth: breakpoint.lg,
        width: breakpoint.lg,
        justifySelf: "start",
        alignSelf: "flex-start",
      }}
    >
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Download Deposits
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Downloads",
            },

            {
              title: "Deposits",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-body">
          <Form
            form={frmDeposits}
            style={{ margin: "20px 20px 20px 0" }}
            // labelCol={{ span: 7, offset: 0 }}
            wrapperCol={{ offset: 0 }}
            // labelAlign="left"
            layout={onlyWidth >= breakpoint.md ? "inline" : "vertical"}
            onFinish={callbackSearchDeposit}
            disabled={submitting || isLoading}
            size="middle"
          >
            <Form.Item
              label={"Date Range"}
              name={"date_range"}
              required
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (
                      !Array.isArray(value) ||
                      (Array.isArray(value) && value.length !== 2)
                    ) {
                      return Promise.reject("Please select 2 dates.");
                    }
                    const date1 = value[0];
                    const date2 = value[1];
                    const diff = dayjs(date2).diff(dayjs(date1), "months");
                    if (diff > 3) {
                      return Promise.reject("Must be less than 3 month.");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <RangePicker inputReadOnly allowEmpty />
            </Form.Item>
            <Form.Item>
              <Dropdown.Button
                trigger={"click"}
                htmlType="submit"
                type="default"
                loading={submitting}
                menu={{
                  items: [
                    {
                      key: "reset",
                      label: "Reset",
                    },
                  ],
                  onClick: (info) => {
                    if (info?.key === "reset") {
                      frmDeposits.resetFields();
                      setSites(
                        sites
                          ?.filter((site) => site?.sitename !== "All Sites")
                          ?.map((site) => ({
                            ...site,
                            deposits: null,
                            operation: "",
                          }))
                      );
                    }
                  },
                }}
              >
                Search
              </Dropdown.Button>
            </Form.Item>
          </Form>
          <Table
            size="middle"
            bordered
            loading={isLoading || submitting}
            columns={[
              {
                title: "Site",
                dataIndex: "sitename",
                align: "left",
                defaultSortOrder: "ascend",
                minWidth: 250,
                sorter: (a, b) => {
                  return a.sitename > b.sitename;
                },
                render: (value, record, index) => {
                  return value === "All Sites" ? (
                    <Text style={{ fontWeight: "bold" }}>{value}</Text>
                  ) : (
                    value
                  );
                },
              },
              // {
              //   title: "Status",
              //   dataIndex: "status",
              //   align: "center",
              //   width: 150,
              // },
              {
                title: "Operation",
                dataIndex: "operation",
                align: "center",
                width: 150,
                render: (value, record, index) => {
                  if (!record?.deposits) {
                    return value;
                  }
                  if (
                    record.deposits?.success &&
                    record.deposits?.semiformatted?.length === 0
                  ) {
                    return <IconBan size={20} color="grey" />;
                  }
                  const { success, message, base64String, filename } =
                    getDownloadObject(record?.deposits);

                  if (!success) {
                    return (
                      <Tooltip title={message}>
                        <IconBan stroke={1.2} size={20} color="red" />
                      </Tooltip>
                    );
                    // return notification.error({
                    //   message: "Failed generating file.",
                    //   placement: "top",
                    //   duration: 4,
                    // });
                  }

                  return (
                    <Button
                      type="link"
                      size="small"
                      href={base64String}
                      download={filename}
                    >
                      <IconFileTypeCsv stroke={1.2} size={20} color="green" />
                    </Button>
                  );
                },
              },
            ]}
            dataSource={sites}
            rowKey={(site) => site?.sitename}
            pagination={{
              position: ["none", "none"],
              defaultPageSize: 100,
            }}
          />
        </div>
      </div>
    </Content>
  );
};

export default Deposits;
