import React, { useState, useEffect } from "react";
import { Modal, Table, notification, Progress } from "antd";
import useAxiosPrivate from "../../../context/hooks/useAxiosPrivate";
import { AxiosError } from "axios";
import { useInterval } from "react-interval-hook";
import moment from "moment";
import { IconLoader, IconPlayerPlayFilled } from "@tabler/icons-react";

const InvokePetrovendSyncTasks = ({ open, onClose }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolderConfirm] = Modal.useModal();
  const { start, stop, isActive } = useInterval(
    async () => {
      await refreshJobStatus();
    },
    1000 * 5,
    { autoStart: false, immediate: false }
  );

  const invokeSyncTask = async (site) => {
    try {
      const _site = sites?.find((row) => row?.id === site?.id);

      _site.last_finished = null;
      _site.progress = { status: "initiating" };
      _site.state = "";

      setSites([...sites]);
      const { data } = await axiosPrivate.post(
        `/admin/fleetcard-toolbox/invoke-petrovend-tasks`,
        { site_id: [site?.id] }
      );
      const { success, message } = data;
      if (!success) {
        _site.state = "failed";
        setSites([...sites]);
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data?.toString() ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    }
  };

  const refreshJobStatus = async () => {
    try {
      const { data } = await axiosPrivate.get(
        "/admin/fleetcard-toolbox/invoke-petrovend-tasks/get-status",
        { timeout: 1000 * 3 }
      );

      const { success, jobs } = data;
      if (success) {
        const updated_sites = sites?.map((site) => ({
          id: site?.id,
          site_name: site?.site_name,
          progress: success
            ? jobs?.find(
                (job) => job?.data?.site_id?.toString() === site?.id.toString()
              )?.progress ?? {}
            : {},
          state: success
            ? jobs?.find(
                (job) => job?.data?.site_id?.toString() === site?.id.toString()
              )?.state ?? {}
            : {},
          last_finished: jobs
            ? jobs?.find(
                (job) => job?.data?.site_id.toString() === site?.id.toString()
              )?.finishedOn ?? ""
            : "",
        }));
        setSites(updated_sites);
      }
    } catch (err) {
      // notification.error({
      //   message:
      //     err instanceof AxiosError
      //       ? err?.response?.data ?? err?.message
      //       : err?.message ?? err?.toString(),
      //   placement: "bottom",
      //   duration: 4,
      // });
    } finally {
    }
  };

  useEffect(() => {
    return () => {
      if (isActive()) {
        stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!isActive() && sites?.length) {
      start();
    }
  }, [sites]);

  return (
    <>
      {contextHolderConfirm}
      <Modal
        closable={false}
        maskClosable={false}
        keyboard={false}
        style={{ minWidth: 500 }}
        styles={{
          body: {
            maxHeight: 550,
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        width={800}
        open={open}
        title={"Invoke Petrovend Sync Tasks"}
        destroyOnClose
        onClose={(e) => {
          onClose();
        }}
        onOk={() => {
          onClose();
        }}
        okText={"Close"}
        okButtonProps={{
          disabled: isLoading,
        }}
        cancelText={"Cancel"}
        cancelButtonProps={{
          hidden: true,
          disabled: true,
        }}
        afterOpenChange={async (open) => {
          if (open) {
            try {
              setIsLoading(true);
              const { data } = await axiosPrivate.get(`/regular/api/get-sites`);
              const { data: dataStatus } = await axiosPrivate.get(
                "/admin/fleetcard-toolbox/invoke-petrovend-tasks/get-status"
              );

              const { success, sites } = data;
              const { success: successJobStatus, jobs } = dataStatus;
              // console.log(jobs);
              if (success) {
                setSites(
                  sites
                    ?.filter(
                      (site) =>
                        ["petrovend-fsc3000", "system 2"].includes(
                          site?.sitehdtype
                        ) &&
                        site?.skipped === "No" &&
                        site?.list_by_fleetcard_manager
                    )
                    ?.map((site) => ({
                      id: site?.id,
                      site_name: site?.sitename,
                      progress: successJobStatus
                        ? jobs?.find(
                            (job) =>
                              job?.data?.site_id.toString() ===
                              site?.id.toString()
                          )?.progress ?? {}
                        : {},
                      state: successJobStatus
                        ? jobs?.find(
                            (job) =>
                              job?.data?.site_id?.toString() ===
                              site?.id.toString()
                          )?.state ?? {}
                        : {},
                      last_finished: successJobStatus
                        ? jobs?.find(
                            (job) =>
                              job?.data?.site_id.toString() ===
                              site?.id.toString()
                          )?.finishedOn ?? ""
                        : "",
                    }))
                );
              } else {
                notification.error({
                  message: "Failed loading site list.",
                  placement: "bottom",
                  duration: 4,
                });
              }
            } catch (err) {
              notification.error({
                message:
                  err instanceof AxiosError
                    ? err?.response?.data ?? err?.message
                    : err?.message ?? err?.toString(),
                placement: "bottom",
                duration: 4,
              });
            } finally {
              setIsLoading(false);
            }
          }
        }}
        afterClose={() => {
          if (isActive()) {
            stop();
          }
          // setSites([]);
        }}
      >
        <Table
          loading={isDownloadInProgress || isLoading}
          size="middle"
          pagination={{ position: ["none", "none"], defaultPageSize: 25 }}
          bordered
          columns={[
            { dataIndex: "site_name", title: "Site Name", align: "left" },
            {
              dataIndex: "progress",
              title: "Status",
              align: "center",
              width: 180,
              responsive: ["md"],
              render: (value, record, index) => {
                //console.log(record?.last_finished);
                return ["failed"].includes(record?.state)
                  ? "failed"
                  : value?.status ?? "-";
              },
            },
            {
              dataIndex: "last_finished",
              title: "Last Finished",
              align: "center",
              width: 150,
              responsive: ["md"],
              render: (value, record, index) => {
                //console.log(record?.last_finished);
                return record?.last_finished
                  ? moment
                      .unix(record?.last_finished.toString().substring(0, 10))
                      .format("MM-DD HH:mm")
                  : "-";
              },
            },
            {
              title: "Action",
              width: 80,
              key: "operation",
              align: "center",
              render: (text, record, index) => {
                if (
                  ["completed", "failed", ""].includes(
                    record?.progress?.status
                  ) ||
                  ["failed", "completed"].includes(record?.state) ||
                  !record?.progress?.status
                ) {
                  return (
                    <IconPlayerPlayFilled
                      size={20}
                      stroke={1.5}
                      color="green"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        await invokeSyncTask(record);
                      }}
                    />
                  );
                } else if (
                  [
                    "logging",
                    "retrieving transactions",
                    "initiating",
                    "syncing cards",
                    "automating",
                    "updating",
                  ].includes(record?.progress?.status)
                ) {
                  return (
                    <IconLoader
                      size={20}
                      stroke={1.5}
                      color="blue"
                      className="icon-spin"
                    />
                  );
                }
              },
            },
          ]}
          dataSource={sites}
          rowKey={(row) => row?.id}
        />
      </Modal>
    </>
  );
};

export default InvokePetrovendSyncTasks;
