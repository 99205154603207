import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import { Buffer } from "buffer";
import { useWindowWidth } from "@react-hook/window-size";
import {
  IconLoader,
  IconAlertTriangle,
  IconDownload,
  IconFileTypeCsv,
  IconFileTypeTxt,
  IconBan,
} from "@tabler/icons-react";
import dayjs from "dayjs";

import {
  Layout,
  Form,
  Button,
  Dropdown,
  Typography,
  notification,
  Breadcrumb,
  Table,
  DatePicker,
  Space,
  message,
} from "antd";

import breakpoint from "../Components/breakpoint";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const Transactions = () => {
  const [frmTransactions] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const onlyWidth = useWindowWidth();
  const [selectedRows, setSelectedRow] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const initCheck = useRef(true);

  useEffect(() => {
    const loadSites = async () => {
      setIsLoading(true);
      try {
        const { data } = await axiosPrivate.get(`/regular/api/get-sites`);
        const { success, sites } = data;

        if (success) {
          setSites([
            ...sites
              ?.filter(
                (site) =>
                  site?.sitehdtype === "infonet" &&
                  site?.testsite === "No" &&
                  site?.skipped === "No" &&
                  site?.list_by_fleetcard_manager
              )
              ?.map((site) => ({
                ...site,
                transactions: null,
                operation: "",
              })),
          ]);
        } else {
          notification.error({
            message: "Failed loading site list.",
            placement: "bottom",
            duration: 4,
          });
        }
      } catch (err) {
        notification.error({
          message:
            err instanceof AxiosError
              ? err?.response?.data ?? err?.message
              : err?.message ?? err?.toString(),
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        await loadSites();
      })();
    }
  }, []);

  const callbackSearchTransactions = async (value) => {
    try {
      setSubmitting(true);
      setSites(
        sites.map((site) => ({
          ...site,
          transactions: null,
          operation: selectedRows?.find((row) => row?.id === site?.id) ? (
            <IconLoader size={20} />
          ) : (
            ""
          ),
        }))
      );
      const { date_range } = value;
      const [date1, date2] = date_range;
      const { data } = await axiosPrivate.post(
        "/regular/downloads/search-transaction",
        {
          date1: dayjs(date1).format("YYYY-MM-DD"),
          date2: dayjs(date2).format("YYYY-MM-DD"),
          site_ids: selectedRows?.map((row) => row?.id),
        }
      );

      const { success, results } = data;
      if (success && results?.length) {
        setSites([
          ...sites
            ?.filter((site) => site?.sitename !== "All Sites")
            ?.map((site) => ({
              ...site,
              transactions: results?.find(
                (result) => result?.sitename === site?.sitename
              ),
            })),
          ,
          {
            sitename: "All Sites",
            transactions: {
              success: true,
              sitename: "CombinedTransaction",
              transdate_b: results[0]?.transdate_b,
              transdate_e: results[0]?.transdate_e,
              csvcontent: sites
                ?.filter(
                  (site) =>
                    site?.export_format === "csv" &&
                    site?.sitehdtype === "infonet"
                )
                .map((site) => {
                  const findResult = results?.find(
                    (result) => result?.sitename === site?.sitename
                  );
                  if (findResult) {
                    return findResult?.csvcontent;
                  } else {
                    return "";
                  }
                })
                .filter((t) => t)
                ?.join("\n"),
              trans: sites
                ?.filter(
                  (site) =>
                    site?.export_format === "csv" &&
                    site?.sitehdtype === "infonet"
                )
                .map((site) => {
                  const findResult = results?.find(
                    (result) => result?.sitename === site?.sitename
                  );
                  if (findResult) {
                    return findResult?.trans;
                  } else {
                    return [];
                  }
                })
                ?.flat(1)
                .filter((t) => t),
            },
          },
        ]);
      } else {
        setSites(
          sites?.map((site) => ({
            ...site,
            transactions: null,
          }))
        );
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err?.response?.data ?? err?.message
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
      setSites(
        sites?.map((site) => ({
          ...site,
          transactions: null,
          operation: <IconAlertTriangle size={20} color="red" />,
        }))
      );
    } finally {
      setSubmitting(false);
    }
  };

  const getDownloadObject = (siteTransactions, k2500 = false) => {
    try {
      const {
        csvcontent,
        k2500: k2500Content,
        sitename,
        transdate_b,
        transdate_e,
      } = siteTransactions;

      let bufferObj = Buffer.from(k2500 ? k2500Content : csvcontent, "utf8");
      let base64String = `data:text/${
        k2500 ? "plain" : "csv"
      };base64,${bufferObj.toString("base64")}`;
      let filename = `transaction-${sitename.replace(
        /\s/g,
        ""
      )}-${transdate_b}-${transdate_e}${k2500 ? ".trn" : ""}`;
      return { success: true, message: "", base64String, filename };
    } catch (err) {
      return { success: false, message: err?.toString() };
    }
  };

  return (
    <Content
      className="admin-generic-content"
      style={{
        maxWidth: breakpoint.lg,
        width: breakpoint.lg,
        justifySelf: "start",
        alignSelf: "flex-start",
      }}
    >
      <div className="admin-generic-title">
        <Title level={4} ellipsis style={{ padding: 0, margin: 0 }}>
          Download Transactions
        </Title>
        <Breadcrumb
          items={[
            {
              title: <NavLink to={"/"}>Home</NavLink>,
            },
            {
              title: "Downloads",
            },

            {
              title: "Transactions",
            },
          ]}
        ></Breadcrumb>
      </div>
      <div className="admin-generic-card">
        <div className="admin-generic-card-body">
          <Form
            form={frmTransactions}
            style={{ margin: "20px 20px 20px 0" }}
            // labelCol={{ span: 7, offset: 0 }}
            wrapperCol={{ offset: 0 }}
            // labelAlign="left"
            layout={onlyWidth >= breakpoint.md ? "inline" : "vertical"}
            onFinish={callbackSearchTransactions}
            disabled={submitting || isLoading}
            size="middle"
          >
            <Form.Item
              label={"Date Range"}
              name={"date_range"}
              required
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (
                      !Array.isArray(value) ||
                      (Array.isArray(value) && value.length !== 2)
                    ) {
                      return Promise.reject("Please select 2 dates.");
                    }
                    const date1 = value[0];
                    const date2 = value[1];
                    const diff = dayjs(date2).diff(dayjs(date1), "months");
                    if (diff > 3) {
                      return Promise.reject("Must be less than 3 month.");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <RangePicker inputReadOnly allowEmpty />
            </Form.Item>
            <Form.Item>
              <Dropdown.Button
                htmlType="submit"
                trigger={"click"}
                type="default"
                loading={submitting}
                disabled={selectedRows?.length === 0}
                menu={{
                  items: [
                    {
                      key: "reset",
                      label: "Reset",
                    },
                  ],
                  onClick: (info) => {
                    if (info?.key === "reset") {
                      frmTransactions.resetFields();
                      setSelectedRow([]);
                      setSelectedRowKeys([]);
                      setSites(
                        sites
                          ?.filter((site) => site?.sitename !== "All Sites")
                          ?.map((site) => ({
                            ...site,
                            transactions: null,
                            operation: "",
                          }))
                      );
                    }
                  },
                }}
              >
                Search
              </Dropdown.Button>
            </Form.Item>
          </Form>
          <Table
            size="middle"
            bordered
            loading={isLoading || submitting}
            columns={[
              {
                title: "Site",
                dataIndex: "sitename",
                align: "left",
                defaultSortOrder: "ascend",
                minWidth: 250,
                sorter: (a, b) => {
                  return a.sitename > b.sitename;
                },
                render: (value, record, index) => {
                  // if (value === "All Sites") {
                  //   console.log(record);
                  // }
                  return value === "All Sites" ? (
                    <Text style={{ fontWeight: "bold" }}>{value}</Text>
                  ) : (
                    value
                  );
                },
              },
              // {
              //   title: "Status",
              //   dataIndex: "status",
              //   align: "center",
              //   width: 150,
              // },
              {
                title: "Operation",
                dataIndex: "operation",
                align: "center",
                width: 150,
                render: (value, record, index) => {
                  if (!record?.transactions) {
                    return value;
                  }
                  if (
                    record.transactions?.success &&
                    ((record.transactions?.csvcontent?.length === 0 &&
                      record.transactions?.k2500?.length === 0) ||
                      !record.transactions?.trans ||
                      (record.transactions?.trans &&
                        !record.transactions?.trans?.length))
                  ) {
                    return <IconBan size={20} color="grey" />;
                  }

                  if (
                    record?.sitename === "All Sites" ||
                    record?.export_format === "csv"
                  ) {
                    const { success, message, base64String, filename } =
                      getDownloadObject(record?.transactions);

                    if (!success) {
                      return notification.error({
                        message: "Failed generating file.",
                        placement: "top",
                        duration: 4,
                      });
                    }

                    return (
                      <Button
                        type="link"
                        size="small"
                        href={base64String}
                        download={filename}
                      >
                        <IconFileTypeCsv stroke={1.2} size={20} color="green" />
                      </Button>
                    );
                  } else if (record?.export_format === "k2500") {
                    const { success, message, base64String, filename } =
                      getDownloadObject(record?.transactions);

                    const {
                      success: k2500_success,
                      message: k2500_message,
                      base64String: k2500_base64String,
                      filename: k2500_filename,
                    } = getDownloadObject(record?.transactions, true);

                    if (!success || !k2500_success) {
                      return notification.error({
                        message: "Failed generating file.",
                        placement: "top",
                        duration: 4,
                      });
                    }

                    return (
                      <Space size="small">
                        <Button
                          size="small"
                          type="link"
                          href={base64String}
                          download={filename}
                        >
                          <IconFileTypeCsv
                            stroke={1.2}
                            size={20}
                            color="green"
                          />
                        </Button>
                        <Button
                          size="small"
                          type="link"
                          href={k2500_base64String}
                          download={k2500_filename}
                        >
                          <IconFileTypeTxt
                            stroke={1.2}
                            size={20}
                            color="orange"
                          />
                        </Button>
                      </Space>
                    );
                  }
                },
              },
            ]}
            dataSource={sites}
            rowKey={(site) => site?.sitename}
            pagination={{
              position: ["none", "none"],
              defaultPageSize: 100,
            }}
            rowSelection={{
              hideSelectAll: false,
              selectedRowKeys: selectedRowKeys,
              onChange: (selectedKeys, selectedRows) => {
                setSelectedRow(selectedRows);
                setSelectedRowKeys(selectedKeys);
              },
              type: "checkbox",
            }}
          />
        </div>
      </div>
    </Content>
  );
};

export default Transactions;
